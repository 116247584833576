import { reset } from 'api/user';
import Header from 'components/Header';
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { TOKEN } from '../api';

const ResetSchema = Yup.object().shape({
  password: Yup.string()
    .required('Veuillez entrer un mot de passe')
    .min(8, 'Le mot de passe doit contenir au moins 8 caractères')
    .matches(/[A-Z]/, 'Le mot de passe doit contenir au moins une majuscule')
    .matches(
      /[+=!@#$%^&*]/,
      'Le mot de passe doit contenir au moins un caractère spécial'
    ),
  confirmPassword: Yup.string()
    .required('Veuillez confirmer votre mot de passe')
    .oneOf(
      [Yup.ref('password'), null],
      'Les mots de passe ne correspondent pas'
    ),
});

const ResetPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const token = location.pathname.split('/')[2];
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const formRef = useRef();

  const updatePassword = (password, resetForm) => {
    setLoading(true);
    reset({
      token,
      password,
    })
      .then(() => {
        setMessage([true, 'Votre mot de passe a bien été réinitialisé']);
        setLoading(false);

        resetForm();
        setTimeout(() => {
          window.location.href = '/se-connecter';
        }, 2000);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setMessage([false, 'Le lien de réinitialisation a expiré']);
        } else {
          setMessage([false, 'Une erreur s’est produite. Veuillez réessayer.']);
        }
        setLoading(false);
      });
  };

  useEffect(() => {
    if (TOKEN) navigate('/');
  }, [TOKEN]);

  return (
    <div className="container mx-auto max-w-7xl md:px-8 lg:px-24">
      <Header />
      <Helmet title="Changer le mot de passe - KOUDPOUSS" />
      <section className="bg-white">
        <div className="flex min-h-screen justify-center p-8 md:p-16 lg:p-24">
          <div className="hidden rounded-l-[60px] bg-secondary/25 bg-cover bg-no-repeat bg-blend-multiply lg:block lg:w-2/5">
            <div className="-ml-24 flex h-full flex-col justify-center">
              <img src="/assets/logo-.png" className="w-2/3" alt="logo" />
            </div>
          </div>

          <div className="mx-auto flex w-full items-center p-8 lg:w-3/5 lg:px-12">
            <div className="w-full">
              <div className="flex h-full flex-row justify-center">
                <img
                  src="/assets/red-envelope.png"
                  className="w-auto scale-110"
                  alt="logo"
                />
              </div>

              <div className="mx-auto mt-4 max-w-md text-center">
                <h1 className="mt-16 text-2xl font-bold text-black">
                  Changer le mot de passe
                </h1>
                <br />

                <Formik
                  ref={formRef}
                  initialValues={{
                    password: '',
                    confirmPassword: '',
                  }}
                  validationSchema={ResetSchema}
                  onSubmit={(values, { resetForm }) => {
                    if (!loading) {
                      updatePassword(values.password, resetForm);
                    }
                  }}
                  classNam="w-full"
                >
                  {({ errors, touched }) => (
                    <Form className="flex w-full flex-col space-y-4">
                      <div className="relative flex w-full">
                        <Field
                          name="password"
                          type={showPassword ? 'text' : 'password'}
                          placeholder="Mot de passe"
                          className="relative mx-auto w-full items-start justify-center rounded-full bg-secondary p-6 text-base leading-tight text-white placeholder:text-white"
                        />
                        <button
                          type="button"
                          className="absolute right-6 top-5 text-xl text-white"
                          onClick={() => setShowPassword(!showPassword)}
                          aria-label={
                            showPassword
                              ? 'Masquer le mot de passe'
                              : 'Afficher le mot de passe'
                          }
                        >
                          <i
                            className={`fas ${
                              !showPassword ? 'fa-eye-slash' : 'fa-eye'
                            }`}
                            aria-hidden="true"
                          />
                        </button>
                      </div>
                      <div className="my-3 flex flex-row justify-between text-xs text-[#808080] md:text-sm">
                        <div className="inline-flex items-center">
                          <span className="mr-2 inline-block h-2 w-2 rounded-full bg-[#808080]" />
                          <span className="text-gray-600">
                            &gt; 8 Caractères
                          </span>
                        </div>
                        <div className="inline-flex items-center">
                          <span className="mr-2 inline-block h-2 w-2 rounded-full bg-[#808080]" />
                          <span className="text-gray-600">&gt; 1 Maj</span>
                          <span className="hidden md:block">uscule</span>
                        </div>
                        <div className="inline-flex items-center">
                          <span className="mr-2 inline-block h-2 w-2 rounded-full bg-[#808080]" />
                          <span className="text-gray-600">
                            &gt; 1 Caractère spécial
                          </span>
                        </div>
                      </div>
                      {errors.password && touched.password ? (
                        <div className="text-red-400">{errors.password}</div>
                      ) : null}

                      <div className="relative flex w-full">
                        <Field
                          name="confirmPassword"
                          type={showConfirmPassword ? 'text' : 'password'}
                          placeholder="Confirmez votre mot de passe"
                          className="relative mx-auto w-full items-start justify-center rounded-full bg-secondary p-6 text-base leading-tight text-white placeholder:text-white"
                        />
                        <button
                          type="button"
                          className="absolute right-6 top-5 text-xl text-white"
                          onClick={() =>
                            setShowConfirmPassword(!showConfirmPassword)
                          }
                          aria-label={
                            showConfirmPassword
                              ? 'Masquer la confirmation du mot de passe'
                              : 'Afficher la confirmation du mot de passe'
                          }
                        >
                          <i
                            className={`fas ${
                              !showConfirmPassword ? 'fa-eye-slash' : 'fa-eye'
                            }`}
                            aria-hidden="true"
                          />
                        </button>
                      </div>
                      {errors.confirmPassword && touched.confirmPassword ? (
                        <div className="text-red-400">
                          {errors.confirmPassword}
                        </div>
                      ) : null}

                      <br />
                      {message.length > 0 && (
                        <div
                          className={`mb-4 text-center text-sm ${
                            message[0] ? 'text-primary' : 'text-red-500'
                          }`}
                        >
                          {message[1]}
                        </div>
                      )}

                      <button
                        type="submit"
                        className={`relative mx-auto w-fit items-start justify-center rounded-full bg-secondary px-9 py-4 text-base font-bold leading-tight text-white
                      ${!loading && 'duration-300 hover:scale-105'}`}
                      >
                        {loading && 'En cours'}
                        {!loading && 'Réinitialiser'}
                      </button>

                      <br />
                      <div className="mt-4 flex flex-col items-center justify-center">
                        <Link to="/se-connecter">
                          <a className="text-sm text-secondary">Se connecter</a>
                        </Link>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ResetPassword;
