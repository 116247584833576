import { publicRequest, userRequest } from 'api';

export const getUserCollect = async (slug) => {
  return userRequest.get(`user/collect/collect/${slug}`);
};

export const getUserCollects = async (page, perPage) => {
  return userRequest.get(
    `user/collect/collect?page=${page}&per_page=${perPage}`
  );
};

export const createCollect = async (data) => {
  return userRequest.post(`user/collect/collect`, data);
};

export const updateCollect = async (data) => {
  return userRequest.post(`user/collect/collecteupdate`, data);
};

export const verifySlug = async (data) => {
  return userRequest.post(`user/collect/verifyslug`, data);
};

export const getPopularCollects = async (data) => {
  return publicRequest.post(`user/collect/collectpopulaire`, data);
};

export const getCollects = async () => {
  return publicRequest.get(`collect/collect`);
};

export const getPageCollects = async (title, page, perPage) => {
  return userRequest.get(
    `collects/search?title=${title}&page=${page}&per_page=${perPage}`
  );
};

export const getCollect = async (slug) => {
  return publicRequest.get(`collect/collect/${slug}`);
};

export const getCollectVotes = async (id) => {
  return publicRequest.get(`collect/collectsvotes/${id}`);
};

export const getCollectSupporters = async (id) => {
  return publicRequest.get(`collect/countsupporter/${id}`);
};

export const getCollectAmount = async (id) => {
  return publicRequest.get(`collect/amount/${id}`);
};

export const getCollectDonations = async (id) => {
  return publicRequest.get(`collect/collectsdons/${id}`);
};
export const getAllCollectSupporters = async (id, page = 1) => {
  return publicRequest.get(`collect/listsupporter/${id}?page=${page}`);
};

export const getCollectNews = async (collectId) => {
  return publicRequest.get(`collect/collectsactualites/${collectId}`);
};

export const deleteCollect = async (id) => {
  return userRequest.delete(`user/collect/collect/${id}`);
};
export const closeCollect = async (id) => {
  return userRequest.post(`user/collect/collect/${id}/close`);
};

export const unlockCollect = async (id) => {
  return userRequest.patch(`user/collect/collect/${id}/reopen`);
};

export const getCollectDonationsByCollectId = async (id) => {
  return userRequest.get(`user/donation/${id}/collect`);
};

export const voteCollect = async (data) => {
  return userRequest.post(`/vote/vote`, data);
};

export const getDons = async (id) => {
  return userRequest.get(`user/collects/${id}/donations`);
};

export const getActiveTirelire = async () => {
  return publicRequest.get('tirelires/active');
};

export const getTirelireAmount = async () => {
  return publicRequest.get('total-tirelire');
};

export const getTirelirePerEvent = async (event) => {
  return publicRequest.get(`total-tirelire-event?event=${event}`);
};

export const getTirelirePerEventRank = async (event) => {
  return publicRequest.get(`tirelire/collections/${event}`);
};
