/* eslint-disable no-unused-vars */
/* eslint-disable unused-imports/no-unused-vars */
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { PropTypes } from 'prop-types';
import React, { useEffect, useState } from 'react';

dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(advancedFormat);

const CountDownTimer = ({ date }) => {
  const [timeUp, setTimeUp] = useState(false);
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const calculateTimeLeft = () => {
      // Ajouter l'heure de fin de journée si seule la date est fournie
      const endDate = date.includes('T')
        ? dayjs.tz(date, 'Europe/Paris')
        : dayjs.tz(`${date}T23:59:59`, 'Europe/Paris');
      const now = dayjs().tz('Europe/Paris');

      console.log('Date fournie:', date);
      console.log('Date de fin interprétée:', endDate.format());
      console.log('Date actuelle:', now.format());

      const difference = endDate.diff(now);

      if (difference <= 0) {
        setTimeUp(true);
        return null;
      }

      // Calculer les jours, heures, minutes et secondes
      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((difference % (1000 * 60)) / 1000);

      return { days, hours, minutes, seconds };
    };

    // Initialiser le timer
    const initialTimeLeft = calculateTimeLeft();
    if (initialTimeLeft) {
      setTimeLeft(initialTimeLeft);
    }

    // Mettre à jour le timer chaque seconde
    const countdown = setInterval(() => {
      const newTimeLeft = calculateTimeLeft();
      if (newTimeLeft) {
        setTimeLeft(newTimeLeft);
      } else {
        clearInterval(countdown);
      }
    }, 1000);

    return () => clearInterval(countdown);
  }, [date]);

  if (timeUp) {
    return (
      <div className="text-center text-primary">La collecte est terminée</div>
    );
  }

  return (
    <ul className="flex flex-wrap justify-between rounded-lg border-4 border-primary bg-slate-300/20 p-5 text-primary">
      <li>
        <p>
          <span className="mb-1 block text-2xl text-secondary">
            {timeLeft.days > 9 ? timeLeft.days : `0${timeLeft.days}`}
          </span>
          <span>jours</span>
        </p>
      </li>
      <li className="text-2xl text-primary">:</li>
      <li>
        <p>
          <span className="mb-1 block text-2xl text-secondary">
            {timeLeft.hours > 9 ? timeLeft.hours : `0${timeLeft.hours}`}
          </span>
          <span>heures</span>
        </p>
      </li>
      <li className="text-2xl text-primary">:</li>
      <li>
        <p>
          <span className="mb-1 block text-2xl text-secondary">
            {timeLeft.minutes > 9 ? timeLeft.minutes : `0${timeLeft.minutes}`}
          </span>
          <span>minutes</span>
        </p>
      </li>
      <li className="text-2xl text-primary">:</li>
      <li>
        <p>
          <span className="mb-1 block text-2xl text-secondary">
            {timeLeft.seconds > 9 ? timeLeft.seconds : `0${timeLeft.seconds}`}
          </span>
          <span>secondes</span>
        </p>
      </li>
    </ul>
  );
};

CountDownTimer.propTypes = {
  date: PropTypes.string.isRequired,
};

export default CountDownTimer;
