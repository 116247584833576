/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */
import '@splidejs/react-splide/css';
import '@splidejs/react-splide/css/core';
/* eslint-disable unused-imports/no-unused-vars */
import 'video-react/dist/video-react.css'; // import css

import { Splide, SplideSlide } from '@splidejs/react-splide';
import { getTirelirePerEventRank } from 'api/collection';
import Activity from 'components/Activity';
import ActivityWireFrame from 'components/ActivityWireframe';
import Banner from 'components/Banner';
import React, { useEffect, useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

const Ranking = () => {
  const BASE_PATH = `/assets/`;
  const activities = [
    {
      image: 'children_humanity.webp',
      title: 'Solidarité',
      subTitle: (
        <>
          Soutenons l’association <br /> Maracca
        </>
      ),
      rate: '70',
      success: (
        <>
          <span className="text-emerald-600">10000 €</span> collecté sur 7000
        </>
      ),
      backgroundColor: 'bg-emerald-600',
      rank: 'rank_one.png',
    },
    {
      image: 'animal.jpg',
      title: 'Animalière',
      subTitle: <>Soutenons Alfred</>,
      rate: '70',
      success: (
        <>
          <span className="text-blue-400">10000 €</span> collecté sur 7000
        </>
      ),
      backgroundColor: 'bg-blue-400',
      rank: 'rank_two.png',
    },
    {
      image: 'wedding.jpeg',
      title: 'Mariage',
      subTitle: (
        <>
          Soutenons l’association <br /> Maracca
        </>
      ),
      rate: '70',
      success: (
        <>
          <span className="text-secondary">10000 €</span> collecté sur 7000
        </>
      ),
      backgroundColor: 'bg-secondary',
      rank: 'rank_three.png',
    },
    {
      image: 'children_humanity.webp',
      title: 'Solidarité',
      subTitle: (
        <>
          Soutenons l’association <br /> Maracca
        </>
      ),
      rate: '70',
      success: (
        <>
          <span className="text-emerald-600">10000 €</span> collecté sur 7000
        </>
      ),
      backgroundColor: 'bg-emerald-600',
      rank: 'rank_four.png',
    },
    {
      image: 'children_humanity.webp',
      title: 'Solidarité',
      subTitle: (
        <>
          Soutenons l’association <br /> Maracca
        </>
      ),
      rate: '70',
      success: (
        <>
          <span className="text-emerald-600">10000 €</span> collecté sur 7000
        </>
      ),
      backgroundColor: 'bg-emerald-600',
      rank: 'rank_five.png',
    },
    {
      image: 'animal.jpg',
      title: 'Animalière',
      subTitle: <>Soutenons Alfred</>,
      rate: '70',
      success: (
        <>
          <span className="text-blue-400">10000 €</span> collecté sur 7000
        </>
      ),
      backgroundColor: 'bg-blue-400',
      rank: 'rank_six.png',
    },
    {
      image: 'wedding.jpeg',
      title: 'Mariage',
      subTitle: (
        <>
          Soutenons l’association <br /> Maracca
        </>
      ),
      rate: '70',
      success: (
        <>
          <span className="text-secondary">10000 €</span> collecté sur 7000
        </>
      ),
      backgroundColor: 'bg-secondary',
      rank: 'rank_seven.png',
    },
    {
      image: 'children_humanity.webp',
      title: 'Solidarité',
      subTitle: (
        <>
          Soutenons l’association <br /> Maracca
        </>
      ),
      rate: '70',
      success: (
        <>
          <span className="text-emerald-600">10000 €</span> collecté sur 7000
        </>
      ),
      backgroundColor: 'bg-emerald-600',
      rank: 'rank_eight.png',
    },
  ];

  const location = useLocation();
  const slug = location.pathname?.split('/')[2]?.toLowerCase();

  const [activitiesList, setActivitiesList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const title = useMemo(() => {
    let newTitle;
    if (slug === 'life_event') {
      newTitle = 'Évènements de la vie';
    } else if (slug === 'happy_event') {
      newTitle = 'Événements heureux';
    } else if (slug === 'humanity_event') {
      newTitle = 'Humanitaire';
    } else {
      newTitle = 'Animalière';
    }
    return newTitle;
  }, [slug]);

  const fetchRankData = async () => {
    try {
      setLoading(true);
      const response = await getTirelirePerEventRank(slug);
      const tirelireData = response.data.data;

      setActivitiesList(
        tirelireData.map((item) => ({
          id: item.collect.data.id,
          title: item.collect.data.title,
          categoryId: item.collect.data.category_id,
          subTitle: item?.collect.data.title,
          amountGoal: item?.collect.data.montant_cagnotte,
          hasFixedAmount: item?.collect.data.montant_is_libre === 1,
          slug: item?.collect.data.slug,
          image: item?.collect.data.image,
          video:
            item?.collect.data.url_video === 'null' ||
            item?.collect.data.url_video === null
              ? ''
              : item?.collect.data.url_video,
          publicCollectedAmount:
            item?.collect.data.is_public_collect_amount === 1,
          is_active: item?.collect.data.is_active,
          publicAmountGoal: item?.collect.data.montant_is_libre === 1,
        }))
      );
      setErrorMessage('');
    } catch (error) {
      console.error('Erreur lors du chargement du classement:', error);
      setErrorMessage(
        error.response?.data?.message || 'Une erreur est survenue'
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRankData();
  }, []);

  return (
    <div className="container mx-auto max-w-7xl md:px-8 lg:px-24">
      <Banner
        image="superheroes.png"
        currentPage={0}
        headerMessageContainer="items-end xl:ml-auto xl:mr-0 lg:text-left"
        bannerClass="h-banner-large"
      />
      <div className="bg-white py-16">
        <Link to="/ma-collecte-populaire">
          <span className="mx-10 block text-red-500 underline">
            Retour sur la tirelire solidaire
          </span>
        </Link>
        <h2 className="mb-10 mt-16 px-3 text-center text-2xl">
          <span className="text-primary">{title}</span>
        </h2>
        <ul className="mx-10 hidden gap-6 lg:grid grid-cols-4">
          {loading &&
            [0, 1, 2, 3].map((i) => <ActivityWireFrame key={`spc_${i}`} />)}
          {errorMessage.length > 0 && (
            <div className="col-span-4 text-center">{errorMessage}</div>
          )}
          {activitiesList?.length > 0 &&
            activitiesList.map((activity) => (
              <li key={activity.id} className="w-full max-w-xs">
                <Activity data={activity} />
              </li>
            ))}
        </ul>
        {/* <ul className="mx-10 mt-10 hidden justify-between lg:flex">
          {activities.slice(4, 8).map((activity) => (
            <li key={activity.title} className="mr-5 w-full max-w-xs">
              <Activity data={activity} />
            </li>
          ))}
        </ul> */}
        <section className="lg:hidden">
          <Splide
            options={{
              perPage: 1,
              perMove: 1,
              arrows: true,
              pagination: false,
              autoplay: false,
              gap: 34,
              rewind: false,
              type: 'slide',
              breakpoints: {
                640: {
                  perPage: 1,
                  perMove: 1,
                  gap: 10,
                },
                980: {
                  perPage: 2,
                  perMove: 2,
                  gap: 10,
                },
                1024: {
                  perPage: 3,
                  perMove: 3,
                  gap: 10,
                },
                1280: {
                  perPage: 4,
                  perMove: 4,
                  gap: 10,
                },
              },
            }}
          >
            {activitiesList.map((activity) => (
              <SplideSlide key={activity.id} className="w-full max-w-xs">
                <Activity data={activity} />
              </SplideSlide>
            ))}
          </Splide>
        </section>
      </div>
    </div>
  );
};

export default Ranking;
