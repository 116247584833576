import { publicRequest, userRequest } from '.';

export const login = async (user) => {
  return publicRequest.post('auth/login', user);
};

export const confirmAccount = async (token) => {
  return publicRequest.get(`auth/confirm/${token}`);
};

export const register = async (user) => {
  return publicRequest.post('auth/register', user);
};

export const request = async (email) => {
  return publicRequest.post('auth/resetpassword', email);
};

export const reset = async (data) => {
  return publicRequest.put('auth/updatepassword', data);
};

export const resetPassword = async (data) => {
  return publicRequest.put('user/updatepassword', data);
};

export const resend = async (user) => {
  return publicRequest.post(`auth/send-confirmation`, user);
};

export const deleteUser = async (id) => {
  return userRequest.delete(`user/${id}`);
};

export const updateUser = async (user) => {
  return userRequest.post(`user/profil`, user, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Accept: 'application/json',
    },
  });
};

export const changeProfil = async (user) => {
  return userRequest.post(`user/changeprofil`, user);
};

export const changeUser = async (user) => {
  return userRequest.put(`user/changeprofil`, user);
};

export const verifyDocuments = async (data) => {
  return userRequest.get(`auth/verifycompletedoc`, data);
};

export const sendUploadDocuments = async (data) => {
  return userRequest.post(`user/completedoc`, data);
};

export const uploadDocuments = async (data) => {
  return userRequest.put(`user/completedoc`, data);
};

export const getArticles = async (id) => {
  return publicRequest.get(`collect/collectsactualites/${id}`);
};

export const createArticles = async (data) => {
  return userRequest.post(`user/actualite/actualite`, data);
};

export const updateArticles = async (data) => {
  return userRequest.put(`user/actualite/actualite`, data);
};

export const deleteArticle = async (id) => {
  return userRequest.delete(`user/actualite/actualite/${id}`);
};

export const get = async () => {
  return userRequest.post('auth/me');
};

export const getUserRole = async () => {
  return publicRequest.get('roles/user');
};

export const getMyProfile = async () => {
  return userRequest.post(`auth/me`);
};

export const sendForm = async (data) => {
  return publicRequest.post(`collect/contact`, data);
};

export const getSupporters = async (page = 1) => {
  return userRequest.get(`user/listesupporter?page=${page}`);
};

export const getStats = async (start_date, end_date) => {
  return userRequest.get(`user/dashboard`, {
    params: {
      start_date,
      end_date,
    },
  });
};

export const getAnalyticStats = async (start_date, end_date) => {
  return userRequest.get(`user/graphe`, {
    params: {
      start_date,
      end_date,
    },
  });
};

// Donation

export const makeAGift = async (id, data) => {
  return userRequest.post(`donation/donation/${id}`, data);
};

export const checkPaymentStatus = async (id) => {
  return userRequest.get(`donation/check-payment-status/${id}`);
};

export const getCitySuggestions = async (input, country) => {
  return publicRequest.get(
    `https://maps.googleapis.com/maps/api/place/autocomplete/json?input=${input}&types=(cities)&components=country:${country}&key=AIzaSyDX4MVyTaZuPq_c30dr7jKbIoFSjTzMo4Q`
  );
};
export const validateProfile = async (formData) => {
  // Envoi direct des données
  return userRequest.post('user/completedoc', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const deleteAccount = async () => {
  return userRequest.delete(`user/delete`);
};

export const addBankAccount = async (data) => {
  return userRequest.post('bank-accounts', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const getBankAccount = async () => {
  return userRequest.get('bank-accounts');
};

export const deleteBankAccount = async (id) => {
  return userRequest.delete(`bank-accounts/${id}`);
};

export const updateBankAccount = async (id, data) => {
  return userRequest.post(`bank-accounts/${id}`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};
