/* eslint-disable import/no-unresolved */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-vars */
/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable prettier/prettier */

import '@splidejs/react-splide/css';
import '@splidejs/react-splide/css/core';
import '@splidejs/react-splide/css/sea-green';

import { Splide, SplideSlide } from '@splidejs/react-splide';
import { motion } from 'framer-motion';
import parse from 'html-react-parser';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import Button from './Button';

const Banner = ({
  image,
  currentPage,
  headerMessageContainer,
  title,
  description,
  additionalClass,
  bgPosition,
  callToAction,
  bannerClass,
  name,
  setName,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;
  const [message, setMessage] = useState({});
  const [active, setActive] = useState(currentPage);
  const [index, set] = useState(0);
  const listMenu = [
    {
      name: localStorage.getItem('persist:root')
        ? 'Tableau de bord'
        : 'Accueil',
      link: localStorage.getItem('persist:root') ? '/tableau-de-bord' : '/',
      items: [],
    },
    {
      name: 'Lancer une collecte',
      link: '/lancer-une-collecte',
      items: [],
    },
    {
      name: 'La Tirelire Solidaire',
      link: '/tirelire',
      items: [
        {
          name: 'Comment ça marche',
          link: '/comment-ca-marche',
        },
        {
          name: 'Ta collecte populaire',
          link: '/ma-collecte-populaire',
        },
      ],
    },
    {
      name: 'Comment débuter',
      link: '/comment-debuter',
      items: [],
    },
  ];
  const sliderRef = useRef();

  function isDataArray() {
    return Array.isArray(image);
  }

  const goToPage = (idx, route) => {
    setActive(idx);
  };

  const logOut = () => {
    localStorage.removeItem('persist:root');
    window.location.href = '/';
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (name) {
      if (name.length < 5) {
        setMessage({
          type: 'error',
          message:
            'Le titre de la collecte doit contenir au moins 5 caractères',
        });
      } else {
        const catContainer = document.querySelector('#categories');
        catContainer.scrollIntoView({ behavior: 'smooth' });
      }
    } else {
      setMessage({
        type: 'error',
        message: 'Veuillez saisir un titre pour votre collecte',
      });
    }
  };

  return (
    <header>
      <section className="flex w-full flex-col">
        <nav
          className="relative z-50 mx-auto w-full space-x-4 bg-white px-4 lg:flex lg:items-center lg:justify-between"
          aria-label="Global"
        >
          <div className="flex w-full items-center justify-between lg:w-auto">
            <a href="/">
              <figure className="mr-auto flex w-24 cursor-pointer items-center md:mt-0 lg:mt-0">
                <img
                  src="/logo.png"
                  className="mr-3 inline-flex h-24"
                  alt="Koudpouss"
                />
              </figure>
            </a>
            <div className="lg:hidden">
              <button
                type="button"
                className="hs-collapse-toggle inline-flex items-center justify-center gap-2 rounded-md bg-white p-2 align-middle text-sm font-medium text-gray-700 shadow-inner transition-all hover:bg-gray-50 focus:outline-none focus:ring-0 focus:ring-offset-transparent"
                data-hs-collapse="#navbar-collapse-with-animation"
                aria-controls="navbar-collapse-with-animation"
                aria-label="Toggle navigation"
              >
                <svg
                  className="h-5 w-5 hs-collapse-open:hidden"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
                  />
                </svg>
                <svg
                  className="hidden h-4 w-4 hs-collapse-open:block"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                >
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                </svg>
              </button>
            </div>
          </div>
          <div
            id="navbar-collapse-with-animation"
            className="hs-collapse hidden grow basis-full overflow-hidden pb-4 transition-all duration-300 lg:block"
          >
            <div className="mt-5 flex flex-col gap-5 lg:mt-0 lg:flex-row lg:items-center lg:justify-end lg:pl-5">
              {listMenu.map((item, itemId) => {
                return (
                  <ul key={`b_${item.name}_${itemId}`}>
                    {item?.items?.length <= 0 && (
                      <li
                        className={`cursor-pointer text-xs ml-3 !border-solid !py-2
                      ${active === itemId ? 'text-primary' : ''}`}
                      >
                        <a
                          className="flex items-center font-medium hover:text-primary"
                          href={item.link}
                        >
                          {item.name}
                        </a>
                      </li>
                    )}
                    {item?.items?.length > 0 && (
                      <li
                        className="hs-dropdown ml-3 cursor-pointer border-solid py-2 text-xs [--adaptive:none] [--strategy:static] lg:[--strategy:fixed]"
                        key={`bi_${item.name}_${itemId}`}
                      >
                        <a
                          id="hs-mega-menu-basic-dr"
                          className="flex items-center font-medium hover:text-primary"
                        >
                          {item.name}
                        </a>

                        <div className="hs-dropdown-menu lg:tri-right left-top relative top-full z-10 hidden opacity-0 transition-[opacity,margin] duration-[0.1ms] before:absolute before:-top-5 before:left-0 before:h-5 before:w-full hs-dropdown-open:opacity-100 md:max-w-md lg:w-48 lg:shadow-md lg:duration-[150ms]">
                          <div className="relative">
                            <div className="absolute inset-0 bg-white lg:bg-opacity-[16%] lg:brightness-[15%]" />
                            <ul className="relative z-50 p-2 lg:backdrop-blur-[30px]">
                              {item.items.map((i, id) => (
                                <li
                                  className={`hs-dropdown sm:[--strategy:absolute]" relative [--strategy:static] [--adaptive:none] lg:text-xs xl:text-sm
                                  ${active === id ? 'text-primary' : ''}`}
                                  key={`bd_${i.name}_${id}`}
                                >
                                  <a
                                    href={i.link}
                                    className="flex items-center gap-x-3.5 rounded-md px-3 py-2 text-sm text-white focus:ring-0 lg:hover:text-primary"
                                  >
                                    {i.name}
                                  </a>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      </li>
                    )}
                  </ul>
                );
              })}
              <div className="flex flex-row justify-between space-x-3 pr-4 lg:hidden">
                <a
                  href="https://www.facebook.com/koudpouss"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex h-12 w-12 items-center justify-center rounded-full bg-light"
                >
                  <img src="/assets/facebook.png" alt="Facebook" />
                </a>
                <a
                  href="https://www.instagram.com/koudpouss/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex h-12 w-12 items-center justify-center rounded-full bg-light"
                >
                  <img src="/assets/instagram.png" alt="Instagram" />
                </a>
                <a
                  href="https://www.linkedin.com/company/koudpouss/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex h-12 w-12 items-center justify-center rounded-full bg-light"
                >
                  <img src="/assets/linkedin.png" alt="Linkedin" />
                </a>
              </div>
            </div>
          </div>
          <div className="hs-dropdown relative hidden [--adaptive:none] [--strategy:static] sm:[--strategy:absolute] lg:block">
            {localStorage.getItem('persist:root') && (
              <button
                type="button"
                className="button-glassmorphism -mt-6 flex items-center px-6 py-4 font-medium text-white xl:px-12"
                aria-label="Menu utilisateur"
              >
                <i className="fas fa-user-circle text-5xl" aria-hidden="true" />
                <i className="fas fa-chevron-down ml-2" aria-hidden="true" />
              </button>
            )}

            {!localStorage.getItem('persist:root') && (
              <a href="/se-connecter">
                <button
                  type="button"
                  className="button-glassmorphism -mt-6 flex items-center px-6 py-4 font-medium text-white"
                >
                  Inscription/Connexion
                </button>
              </a>
            )}

            {localStorage.getItem('persist:root') && (
              <div className="hs-dropdown-menu w-fill relative top-full z-10 hidden opacity-0 transition-[opacity,margin] duration-[0.1ms] before:absolute before:-top-5 before:left-0 before:h-5 before:w-full hs-dropdown-open:opacity-100 md:max-w-sm lg:w-48 lg:shadow-md lg:duration-[150ms]">
                <div className="relative">
                  <div className="absolute inset-0 bg-white bg-opacity-[16%] brightness-[15%]" />
                  <ul className="relative z-50 p-2 backdrop-blur-[30px]">
                    <li>
                      <a
                        className="flex items-center gap-x-3.5 rounded-md px-3 py-2 text-sm text-white focus:ring-0 lg:hover:text-primary"
                        href="/mon-profil"
                      >
                        Mon profil
                      </a>
                    </li>
                    <li>
                      <a
                        className="flex items-center gap-x-3.5 rounded-md px-3 py-2 text-sm text-white focus:ring-0 lg:hover:text-primary"
                        href="/mes-collectes"
                      >
                        Mes collectes
                      </a>
                    </li>
                    <li>
                      <button
                        type="button"
                        className="flex items-center gap-x-3.5 rounded-md px-3 py-2 text-sm text-white focus:ring-0 lg:hover:text-primary"
                        onClick={logOut}
                      >
                        Déconnexion
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            )}
          </div>
          <div className="hidden flex-row justify-between space-x-3 pr-4 xl:flex">
            <a
              href="https://www.facebook.com/koudpouss"
              target="_blank"
              rel="noopener noreferrer"
              className="flex h-8 w-8 items-center justify-center rounded-full bg-light"
            >
              <img src="/assets/facebook.png" alt="Facebook" />
            </a>
            <a
              href="https://www.instagram.com/koudpouss/"
              target="_blank"
              rel="noopener noreferrer"
              className="flex h-8 w-8 items-center justify-center rounded-full bg-light"
            >
              <img src="/assets/instagram.png" alt="Instagram" />
            </a>
            <a
              href="https://www.linkedin.com/company/koudpouss/"
              target="_blank"
              rel="noopener noreferrer"
              className="flex h-8 w-8 items-center justify-center rounded-full bg-light"
            >
              <img src="/assets/linkedin.png" alt="Linkedin" />
            </a>
          </div>
        </nav>
        {!isDataArray() && title && (
          <section
            className={`mx-auto flex w-full max-w-2xl grow flex-col place-content-center text-center md:-mt-32 lg:mx-auto ${headerMessageContainer} px-6 pb-10 text-2xl font-bold`}
          >
            <article
              className={`min-h-full items-center justify-center ${
                path === '/' ? 'mt-14' : 'mt-0 space-y-6'
              }`}
            >
              <h1 className="text-3xl">{title}</h1>
              <p className="text-xl">{description}</p>
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.9 }}
                type="button"
                className={`mx-auto block rounded-2xl bg-white py-3 text-xl font-medium text-secondary lg:mx-0 ${additionalClass}`}
              >
                {callToAction}
              </motion.button>
            </article>
          </section>
        )}
      </section>
      <section
        className={`h-banner relative flex w-full flex-col ${bannerClass}`}
      >
        {!isDataArray() && (
          <div
            className={`absolute inset-0 -z-20 bg-cover bg-center bg-no-repeat
            ${bgPosition}`}
            style={{
              backgroundImage: `url(/assets/${image})`,
            }}
          />
        )}
        {isDataArray() && (
          <Splide
            ref={sliderRef}
            options={{
              perPage: 1,
              perMove: 1,
              arrows: false,
              pagination: false,
              autoplay: true,
              type: 'loop',
            }}
            onActive={() => {
              if (sliderRef.current) {
                set(sliderRef.current.splide.index);
              }
            }}
            aria-label="testimonies"
            className="!absolute !inset-0 -z-20 !p-0"
          >
            {image.map((img, i) => (
              <SplideSlide
                className="h-banner-large bg-center bg-cover bg-no-repeat lg:!mt-0"
                key={i}
                style={{
                  backgroundImage: `url(/assets/${img.src})`,
                  backgroundPosition: img.position,
                  transition: 'background 0.2s linear',
                }}
              />
            ))}
          </Splide>
        )}
        {isDataArray() && (
          <section className="absolute -bottom-[10%] flex w-full max-w-2xl grow flex-col px-6 pb-10 text-left text-2xl font-bold sm:bottom-6 lg:bottom-12 lg:ml-8 lg:text-left">
            <article className="min-h-full items-end justify-start">
              <h1 className="text-lg text-white sm:text-xl lg:text-3xl">
                {parse(image[index]?.title)}
              </h1>
              <br />
              <form onSubmit={handleSubmit}>
                <div className="flex max-w-md">
                  <input
                    name="name"
                    placeholder="Donne un titre à ta collecte"
                    className="mx-auto mr-2 w-full rounded-full border border-white bg-transparent p-4 text-sm font-medium text-white placeholder:text-white sm:text-base md:block lg:text-lg"
                    onChange={(e) => setName(e.target.value)}
                  />

                  <Button
                    type="submit"
                    text="C’est parti !"
                    className="bg-glassmorphism mx-auto w-fit whitespace-nowrap bg-white text-xs text-white sm:text-sm md:block md:px-4 md:text-xs"
                  />
                </div>
                <p
                  className={`mt-1 ml-2 text-xs text-white
                    ${message.type === 'error' ? 'block' : 'hidden'}
                    `}
                >
                  {message.message}
                </p>
              </form>
            </article>
          </section>
        )}
      </section>
    </header>
  );
};

Banner.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  callToAction: PropTypes.string,
  additionalClass: PropTypes.string,
  bgPosition: PropTypes.string,
  headerMessageContainer: PropTypes.string,
  currentPage: PropTypes.number,
  // eslint-disable-next-line react/forbid-prop-types
  image: PropTypes.any,
  bannerClass: PropTypes.string,
  name: PropTypes.string,
  setName: PropTypes.func,
};

export default Banner;
