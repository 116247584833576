/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import PropTypes from 'prop-types';
import React from 'react';

const Event = (props) => {
  const create = () => {
    if (props.name) {
      window.location.href = `/choisir-un-titre/categorie/${props.data.title.toLowerCase()}/nom/${
        props.name
      }`;
    } else {
      window.location.href = `/choisir-un-titre/categorie/${props.data.title.toLowerCase()}`;
    }
  };

  return (
    <figure
      className={`group mb-10 flex w-48 cursor-pointer flex-col items-center overflow-hidden rounded-b-md ${
        props.data.cardBackground
          ? props.data.cardBackground
          : 'bg-slate-400/20'
      } pt-6`}
      onClick={create}
    >
      <span
        className="ovale bg-center mx-6 block h-36
         w-36 bg-cover bg-no-repeat transition-all duration-300 group-hover:scale-95"
        style={{
          backgroundImage: `url(${props.data.image}`,
          backgroundColor: `${props.data.color}`,
        }}
      />
      <button
        type="button"
        className="mt-5 w-full p-4 text-center text-white"
        style={{
          backgroundColor: props.data.color || 'black',
        }}
      >
        {props.data.title}
      </button>
    </figure>
  );
};

Event.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    image: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    imageDim: PropTypes.string,
    cardBackground: PropTypes.string,
  }).isRequired,
  name: PropTypes.string,
};

export default Event;
