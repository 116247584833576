/* eslint-disable jsx-a11y/label-has-associated-control */
import { Field, Form, Formik } from 'formik';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import * as Yup from 'yup';

import { sendForm } from '../api/user';
import Banner from '../components/Banner';

const ContactSchema = Yup.object().shape({
  name: Yup.string().required('Nom & prénom requis'),
  email: Yup.string().email('Email incorrect').required('Adresse email requis'),
  message: Yup.string().required('Message requis'),
});
const Contact = () => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState([]);

  return (
    <div className="container mx-auto max-w-7xl md:px-8 lg:px-24">
      <Helmet title="Contact - KOUDPOUSS" />
      <Banner
        image="children_river.jpg"
        currentPage={0}
        headerMessageContainer="items-end xl:ml-auto xl:mr-0 lg:text-left"
        bannerClass="h-banner-large"
      />
      <div className="bg-white">
        <section className="mx-auto -mt-24 max-w-lg px-6 py-14 text-base leading-7 md:max-w-5xl">
          {/* Our contact */}
          <article className="md:flex">
            <iframe
              title="koudpouss-address"
              className="mb-8 h-96 md:mr-4 md:h-auto md:w-1/2"
              id="gmap_canvas"
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2626.70194972017!2d2.3243698!3d48.825748!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e671ae17e3410d%3A0x59a503e0b439e9f3!2s101%20Av.%20du%20G%C3%A9n%C3%A9ral%20Leclerc%2C%2075014%20Paris%2C%20France!5e0!3m2!1sfr!2sbj!4v1664124984858!5m2!1sfr!2sbj"
              frameBorder="0"
              scrolling="no"
              marginHeight="0"
              marginWidth="0"
            />
            <ul className="mb-8 border-4 border-red-100 bg-white px-6 font-bold text-black md:w-1/2">
              <li className="flex flex-col py-10 sm:flex-row">
                <label className="w-20 text-primary">Adresse</label>
                <span className="mx-6 hidden text-primary sm:block">:</span>
                <span className="pr-3">
                  101 avenue du Général Leclerc <br /> 75685 PARIS CEDEX 14
                </span>
              </li>
              <li className="flex flex-col border-y-4 border-y-red-100 py-10 sm:flex-row">
                <label className="w-20 text-primary">Telephone</label>
                <span className="mx-6 hidden text-primary sm:block">:</span>
                <a href="tel:+990001112225500">(+99 000 111 222 55 00)</a>
              </li>
              <li className="flex flex-col border-b-4 border-b-red-100 py-10 sm:flex-row">
                <label className="w-20 text-primary">E-mail</label>
                <span className="mx-6 hidden text-primary sm:block">:</span>
                <a href="mailto:contact@koud-pouss.com">
                  contact@koud-pouss.com
                </a>
              </li>
              <li className="flex flex-col items-start py-10 sm:flex-row">
                <strong className="w-40 text-primary">Réseaux sociaux</strong>
                <div className="inline-flex flex-row justify-between space-x-3 pr-4">
                  <a
                    href="https://www.facebook.com/koudpouss"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex h-12 w-12 items-center justify-center rounded-full bg-light"
                  >
                    <img src="/assets/facebook.png" alt="Facebook" />
                  </a>
                  <a
                    href="https://www.instagram.com/koudpouss/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex h-12 w-12 items-center justify-center rounded-full bg-light"
                  >
                    <img src="/assets/instagram.png" alt="Instagram" />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/koudpouss/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex h-12 w-12 items-center justify-center rounded-full bg-light"
                  >
                    <img src="/assets/linkedin.png" alt="Linkedin" />
                  </a>
                </div>
              </li>
            </ul>
          </article>
          <h1 className="text-xl font-bold text-primary">
            On reste en contact ?
          </h1>
          <p className="my-8 text-xl">
            Besoin d’un renseignement ?&nbsp;
            <a href="/faq">
              <span className="font-bold text-primary underline">
                Consulter la foire aux questions
              </span>
            </a>
          </p>
          <p className="mb-16 text-xl">
            Si vous n’avez pas trouvé la réponse à votre question,
            contactez-nous via ce formulaire. Nous vous répondrons dans les plus
            brefs délais.
          </p>
          <Formik
            initialValues={{
              name: '',
              email: '',
              message: '',
            }}
            validationSchema={ContactSchema}
            onSubmit={(values) => {
              const data = {
                name: values.name,
                email: values.email,
                contenu: values.message,
              };
              if (!loading) {
                sendForm(data)
                  .then(() => {
                    setLoading(false);
                    setMessage([
                      true,
                      'Votre message a été envoyé avec succès',
                    ]);
                  })
                  .catch(() => {
                    setLoading(false);
                    setMessage([false, "Une erreur s'est produite"]);
                  });
              }
            }}
          >
            {({ errors, touched }) => (
              <Form className="flex w-full flex-col space-y-4">
                <div className="sm:flex">
                  <div className="sm:mr-5 sm:w-1/2">
                    <Field
                      name="name"
                      type="text"
                      placeholder="Nom & prénom"
                      className="w-full border-4 border-red-100 p-3 text-base"
                    />
                    {errors.name && touched.name ? (
                      <div className="text-red-400">{errors.name}</div>
                    ) : null}
                  </div>
                  <div className="mt-5 sm:mt-0 sm:w-1/2">
                    <Field
                      name="email"
                      type="email"
                      placeholder="Adresse email"
                      className="w-full border-4 border-red-100 p-3 text-base"
                    />
                    {errors.email && touched.email ? (
                      <div className="text-red-400">{errors.email}</div>
                    ) : null}
                  </div>
                </div>

                <div className="relative w-full">
                  <Field
                    className="h-36 w-full border-4 border-red-100 p-3 text-base"
                    name="message"
                    as="textarea"
                    placeholder="Écrire un message"
                  />
                </div>
                {errors.message && touched.message ? (
                  <div className="text-red-400">{errors.message}</div>
                ) : null}

                <br />
                {message.length > 0 && (
                  <div
                    className={`mb-4 text-center text-sm ${
                      message[0] ? 'text-green-500' : 'text-red-500'
                    }`}
                  >
                    {message[1]}
                  </div>
                )}
                <button
                  type="submit"
                  className={`relative w-fit items-start justify-center rounded-full bg-gradient-to-b from-orange-100 to-primary px-9 py-4 text-base font-bold leading-tight text-white
                        ${!loading && 'duration-300 hover:scale-105'}`}
                >
                  {loading ? 'En cours' : 'Envoyer'}
                </button>
              </Form>
            )}
          </Formik>
        </section>
      </div>
    </div>
  );
};

export default Contact;
