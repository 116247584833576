import { PropTypes } from 'prop-types';
import React from 'react';

import useProgressiveImg from './useProgressiveImg';

const Image = (props) => {
  const [src, { blur }] = useProgressiveImg(props.loader, props.src);
  return (
    <img
      src={src}
      style={{
        width: props.width,
        height: props.height,
        filter: blur ? 'blur(20px)' : 'none',
        transition: blur ? 'none' : 'filter 0.3s ease-out',
      }}
      alt={props.alt}
      title={props.title}
      className={props.className}
    />
  );
};

Image.propTypes = {
  loader: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  alt: PropTypes.string,
  title: PropTypes.string,
  className: PropTypes.string,
};

export default Image;
