/* eslint-disable no-unused-vars */
/* eslint-disable unused-imports/no-unused-vars */
import { getCategory } from 'api/category';
import { getCollectAmount } from 'api/collection';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';

const Activity = (props) => {
  const [rate, setRate] = useState(0);
  const [title, setTitle] = useState('');
  const [color, setColor] = useState('');
  const [reached, setReached] = useState(0);

  const rateRatio = () => {
    if (reached > 0 && props.data.target > 0) {
      const ratio = reached / props.data.target;
      setRate(ratio * 100);
    } else {
      setRate(3);
    }
  };

  const getAmount = async () => {
    getCollectAmount(props.data?.id).then((res) => {
      setReached(res.data.montant);
    });
  };

  const fetchCategory = () => {
    getCategory(props.data?.categoryId).then((res) => {
      setTitle(res.data.data.title);
      setColor(res.data.data.color);
    });
  };

  useEffect(() => {
    getAmount();
    fetchCategory();
  }, []);

  useEffect(() => {
    getAmount();
    fetchCategory();
  }, [props.data]);

  useEffect(() => {
    if (reached) rateRatio();
  }, [reached]);

  return (
    <a href={`/collecte/${props.data.slug}`}>
      <figure className="mb-10 flex h-full flex-col bg-slate-400/20 transition-all hover:shadow-lg">
        {props.data.image && !props.data.video && (
          <span
            className="bg-center block h-52 w-full bg-cover bg-no-repeat"
            style={{
              backgroundImage: `url(${props.data.image})`,
            }}
          />
        )}
        {props.data.video && (
          <div className="mx-auto h-52 w-full">
            <ReactPlayer
              width="100%"
              height="100%"
              url={props.data.video}
              controls
            />
          </div>
        )}
        <figcaption className="flex grow flex-col justify-between px-3 pb-10 pt-6 text-left">
          <h3>Collecte {title}</h3>
          <p className="font-bold">{props.data.subTitle}</p>
          <div className="-mt-14 flex grow flex-col justify-end">
            {props.data.publicCollectedAmount && (
              <div className="mb-5 mt-10 h-2.5 w-full rounded-full bg-slate-400/20">
                <div
                  className="h-2.5 rounded-full transition-all duration-300 ease-in-out"
                  style={{
                    width: `${rate}%`,
                    background: color,
                  }}
                />
              </div>
            )}
            {props.data.publicCollectedAmount && (
              <p className="font-bold">
                <span
                  style={{
                    color,
                  }}
                >
                  {reached} €
                </span>{' '}
                collecté
                {props.data.target ? ` sur ${props.data.target} €` : null}
              </p>
            )}
            <div
              className={`flex ${
                props.data.rank ? 'justify-between' : 'justify-start'
              } mt-5 items-center`}
            >
              <span
                className="rounded-full px-8 py-2 text-center text-white"
                style={{
                  background: color,
                }}
              >
                Je supporte
              </span>
            </div>
          </div>
        </figcaption>
      </figure>
    </a>
  );
};

Activity.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string.isRequired,
    subTitle: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    categoryId: PropTypes.string.isRequired,
    target: PropTypes.string.isRequired,
    rank: PropTypes.string,
    slug: PropTypes.string.isRequired,
    video: PropTypes.string,
    publicCollectedAmount: PropTypes.bool,
  }),
};

export default Activity;
