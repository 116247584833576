import axios from 'axios';

export const BASE_URL = process.env.REACT_APP_API_URL;
export const TOKEN =
  typeof window !== 'undefined' && localStorage?.getItem('persist:root')
    ? JSON.parse(localStorage.getItem('persist:root'))?.user?.accessToken
    : '';

export const publicRequest = axios.create({
  baseURL: BASE_URL,
});

export const userRequest = axios.create({
  baseURL: BASE_URL,
  headers: {
    Authorization: `Bearer ${TOKEN}`,
  },
});
