import React from 'react';

const ActivityWireFrame = () => {
  return (
    <figure className="mb-10 flex h-full flex-col bg-slate-400/20">
      <div className="bg-center block h-52 w-full animate-pulse bg-gray-200 bg-cover bg-no-repeat" />
      <figcaption className="flex grow flex-col justify-between px-3 pb-10 pt-6 text-left">
        <h3 className="bg-gray-40 select-non3 text-transparent">Collecte</h3>
        <p className="bg-gray-300 font-bold text-transparent" />
        <div className="-mt-14 flex grow flex-col justify-end">
          <div className="mb-5 mt-10 h-2.5 w-full rounded-full bg-slate-400/20">
            <div className="h-2.5 rounded-full transition-all duration-300 ease-in-out" />
          </div>
          <p className="select-none bg-gray-300 font-bold text-transparent">
            collecté
          </p>
          <div className="mt-5 flex items-center justify-start">
            <button
              type="button"
              className="cursor-default select-none rounded-full bg-gray-300 px-8 py-2 text-center text-transparent"
            >
              Je supporte
            </button>
          </div>
        </div>
      </figcaption>
    </figure>
  );
};

export default ActivityWireFrame;
