import PropTypes from 'prop-types';
import React from 'react';

const Skeleton = ({ className, ...props }) => {
  return (
    <div
      className={`animate-pulse rounded-md bg-gray-200 ${className || ''}`}
      {...props}
    />
  );
};

Skeleton.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  style: PropTypes.shape({}), // Using shape instead of forbidden object type
  id: PropTypes.string,
  role: PropTypes.string,
  'aria-label': PropTypes.string,
  'data-testid': PropTypes.string,
  onClick: PropTypes.func,
  onKeyDown: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  tabIndex: PropTypes.number,
};

Skeleton.defaultProps = {
  className: '',
  children: null,
  style: null,
  id: undefined,
  role: undefined,
  'aria-label': undefined,
  'data-testid': undefined,
  onClick: undefined,
  onKeyDown: undefined,
  onFocus: undefined,
  onBlur: undefined,
  tabIndex: undefined,
};

export { Skeleton };
