import Header from 'components/Header';
import IconSearch from 'components/icons/IconSearch';
import SubHeader from 'components/SubHeader';
import { Field, Form, Formik } from 'formik';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import * as Yup from 'yup';

const TransferHistorySchema = Yup.object().shape({
  search: Yup.string(),
});
const TransferHistory = () => {
  const [loading, setLoading] = useState(false);
  const transfersList = [
    {
      id: 0,
      details: {
        Collecte: 'Soutenons l’association Maraca',
        Montant: '10000 €',
        Date: '21-09-2021 à 11H',
        Statut: <span className="text-cyan-400">Validé</span>,
      },
    },
    {
      id: 1,
      details: {
        Collecte: 'Soutenons Alfred',
        Montant: '10000 €',
        Date: '21-09-2021 à 11H',
        Statut: <span className="text-cyan-400">En cours</span>,
      },
    },
  ];

  return (
    <div className="container mx-auto max-w-7xl md:px-8 lg:px-24">
      <Helmet title="Historique de mes virements - KOUDPOUSS" />

      <Header />
      <section className="bg-white px-6 pb-20 pt-6">
        {/* Menus */}
        <SubHeader currentPage={3} />
        <section className="sm:px-20">
          <h1 className="mb-12 mt-6 px-10 text-center text-2xl text-primary">
            Historique de mes virements
          </h1>
          {/* Search Form */}
          <Formik
            initialValues={{ search: '' }}
            validationSchema={TransferHistorySchema}
            // eslint-disable-next-line no-unused-vars, unused-imports/no-unused-vars
            onSubmit={(values) => {
              if (!loading) {
                setLoading(false);
              }
            }}
          >
            <Form className="relative flex items-center justify-end gap-4 sm:mr-10 sm:pr-10 lg:mb-0">
              <div className="flex w-full max-w-xs rounded-full border border-black bg-gray-300/20 p-3.5">
                <Field
                  name="search"
                  type="text"
                  placeholder="Soutenons l’asso…"
                  className="grow bg-transparent"
                />
                <figure className="text-primary">
                  <IconSearch />
                </figure>
              </div>
              <span className="text-primary">
                {loading ? 'En cours' : 'Filtrer'}
              </span>
            </Form>
          </Formik>
          {/* Results */}
          <div className="mt-10 border border-gray-200 bg-white px-5 pb-10 shadow-md sm:px-10">
            {transfersList.map((transfer) => {
              return (
                <div
                  key={`private_${transfer.id}`}
                  className="mx-auto mt-10 max-w-lg bg-white"
                >
                  <ul className="mx-auto border-t border-gray-300">
                    {Object.keys(transfer.details).map((key) => {
                      return (
                        <li
                          key={`private_${key}`}
                          className="flex flex-col border-x border-b border-gray-300 p-3.5 font-bold sm:flex-row"
                        >
                          <span className="w-44 shrink-0 text-primary">
                            {key}
                          </span>
                          <span className="grow">{transfer.details[key]}</span>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              );
            })}
            <nav className="mt-10 flex items-center justify-center space-x-2">
              <a
                className="text-black-500 inline-flex h-10 w-10 items-center justify-center rounded-full border border-amber-100 text-sm font-medium hover:border-none hover:bg-amber-600 hover:text-white"
                href="#"
              >
                &lt;
              </a>
              <a
                className="inline-flex h-10 w-10 items-center justify-center rounded-full bg-amber-600 text-sm font-medium text-white"
                href="#"
                aria-current="page"
              >
                1
              </a>
              <a
                className="text-black-500 hidden h-10 w-10 items-center justify-center rounded-full border border-amber-100 text-sm font-medium hover:border-none hover:bg-amber-600 hover:text-white sm:inline-flex"
                href="#"
              >
                2
              </a>
              <a
                className="text-black-500 hidden h-10 w-10 items-center justify-center rounded-full border border-amber-100 text-sm font-medium hover:border-none hover:bg-amber-600 hover:text-white sm:inline-flex"
                href="#"
              >
                3
              </a>
              <a
                className="text-black-500 inline-flex h-10 w-10 items-center justify-center rounded-full border border-amber-100 text-sm font-medium hover:border-none hover:bg-amber-600 hover:text-white"
                href="#"
              >
                ...
              </a>
              <a
                className="text-black-500 inline-flex h-10 w-10 items-center justify-center rounded-full border border-amber-100 text-sm font-medium hover:border-none hover:bg-amber-600 hover:text-white"
                href="#"
              >
                10
              </a>
              <a
                className="text-black-500 inline-flex h-10 w-10 items-center justify-center rounded-full border border-amber-100 text-sm font-medium hover:border-none hover:bg-amber-600 hover:text-white"
                href="#"
              >
                <span aria-hidden="true">&gt;</span>
              </a>
            </nav>
          </div>
        </section>
      </section>
    </div>
  );
};

export default TransferHistory;
