import { PropTypes } from 'prop-types';
import React, { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import {
  AlignDropdown,
  BackgroundColorPicker,
  BoldButton,
  CodeFormatButton,
  Divider,
  Editor,
  EditorComposer,
  InsertDropdown,
  InsertLinkButton,
  ItalicButton,
  TextColorPicker,
  TextFormatDropdown,
  ToolbarPlugin,
  UnderlineButton,
} from 'verbum-refined';

const InternalEditor = ({ content, setContent, isPreview }) => {
  useEffect(() => {
    document.addEventListener('click', (e) => {
      const dropdownContainer = document.getElementsByClassName('dropdown');
      const toolbarContainer = document.getElementsByClassName('toolbar');

      if (
        toolbarContainer[0]?.contains(e.target) ||
        dropdownContainer?.length > 0
      ) {
        // eslint-disable-next-line no-restricted-syntax
        for (const dropdownContainerElement of dropdownContainer) {
          dropdownContainerElement.style.top = `${
            window.pageYOffset + e.target.getBoundingClientRect().top
          }px`;
          if (!isMobile) {
            dropdownContainerElement.style.left = `${
              e.target.getBoundingClientRect().left
            }px`;
          } else if (
            e.target.getBoundingClientRect().left >
            window.innerWidth / 2
          ) {
            dropdownContainerElement.style.left = `${
              e.target.getBoundingClientRect().left - 200
            }px`;
          }
        }
      }
    });
    return () => {
      document.removeEventListener('click', () => {});
    };
  }, []);

  return (
    <EditorComposer>
      <Editor
        hashtagsEnables
        initialEditorState={content}
        isReadOnly={isPreview || false}
        onChange={(editorState) => setContent(editorState)}
      >
        {!isPreview && (
          <ToolbarPlugin defaultFontSize="20px">
            <BoldButton />
            <ItalicButton />
            <UnderlineButton />
            <CodeFormatButton />
            <InsertLinkButton />
            <TextColorPicker />
            <BackgroundColorPicker />
            <TextFormatDropdown />
            <Divider />
            <InsertDropdown
              enableTable
              enableYoutube
              enableTwitter
              enableImage
              enableHorizontalRule
            />
            <Divider />
            <AlignDropdown />
          </ToolbarPlugin>
        )}
      </Editor>
    </EditorComposer>
  );
};

InternalEditor.propTypes = {
  content: PropTypes.string,
  setContent: PropTypes.func,
  isPreview: PropTypes.bool,
};

export default InternalEditor;
