import React from 'react';
import { Helmet } from 'react-helmet';

import Banner from '../components/Banner';

const About = () => {
  const BASE_PATH = `/assets/`;

  return (
    <div className="container mx-auto max-w-7xl md:px-8 lg:px-24">
      <Helmet title="A propos de nous - KOUDPOUSS" />
      <Banner
        image="Broadcust.webp"
        currentPage={0}
        headerMessageContainer="items-end xl:ml-auto xl:mr-0 lg:text-left"
      />
      <div className="bg-white">
        <section className="container mx-auto max-w-7xl px-6 py-16 text-base font-normal leading-7 md:px-8 lg:px-24">
          {/* About us  */}
          <article className="mb-16 lg:flex">
            <figure className="relative mt-10 lg:w-1/2">
              <div
                className="aspect-square bg-center ml-20 mr-28 scale-100 rounded-xl bg-cover bg-no-repeat lg:h-full"
                style={{
                  backgroundImage: `url(${BASE_PATH}children_humanity.webp`,
                }}
              />
              <div
                className="aspect-h-1 aspect-w-1 absolute top-0 w-32 scale-110 bg-contain bg-no-repeat"
                style={{
                  backgroundImage: `url(${BASE_PATH}child.webp`,
                }}
              />
              <div
                className="absolute bottom-0 left-0 h-32 w-20 bg-contain bg-no-repeat"
                style={{
                  backgroundImage: `url(${BASE_PATH}children_humanity_small.webp`,
                }}
              />
              <div
                className="absolute right-2 top-0 h-32 w-20 bg-contain bg-no-repeat"
                style={{
                  backgroundImage: `url(${BASE_PATH}children_humanity_small.webp`,
                }}
              />
            </figure>
            <aside className="lg:w-1/2">
              <h1 className="my-10 text-3xl text-primary">A propos de nous</h1>
              <p>
                Un projet en tête, envie de faire une surprise à un proche, un
                rêve à concrétiser, un évènement heureux à célébrer… <br /> Mais
                vous ne savez pas comment obtenir un financement afin de
                matérialiser votre initiative. <br /> Vous êtes une entreprise,
                une association ou un particulier et souhaitez participer au
                financement d’un projet, faire un don, soutenir une cause qui
                vous tient à cœur. <br /> Koud Pouss est une plateforme de
                financement participatif « crowdgiving « qui permet de lever des
                fonds afin de financer diverses causes.
              </p>
            </aside>
          </article>
          {/* About us (more)  */}
          <p>
            Véritable moyen de financement alternatif, nous mettons en relation
            les contributeurs et les porteurs de projets afin de pouvoir lever
            des fonds et permettre à certaines initiatives de voir le jour.{' '}
            <br /> Plus besoin de faire appel aux banques. <br /> Koud Pouss
            innove et ne ressemble à aucune autre plateforme. Nous avons
            souhaité vous offrir une nouvelle expérience et moderniser la
            collecte en ligne tant par le design que par le partage des
            ressources. Nous avons à cœur une réussite commune et partagée.{' '}
            <br /> Koud Pouss s&apos;engage à être le partenaire de la réussite
            de votre objectif. <br /> Nous mettons à votre disposition la
            Tirelire Solidaire qui récompense les collectes les plus populaires
            de leur catégorie. <br /> Pour assurer ses transactions en ligne,
            Koud Pouss s&apos;appuie sur Stripe, des modèles de paiements
            électroniques les plus innovants du moment. Avec cette méthode
            opératoire, Koud Pouss garantit à ses utilisateurs des transactions
            en ligne fiables, transparentes et sécurisées.
          </p>
          {/* Why  */}
          <article>
            <h2 className="my-5 text-center text-2xl text-primary">
              Pourquoi Koud Pouss
            </h2>
            <p>
              Nos procédures et nos services sont guidés par des principes
              visant à garantir la sécurité et la confidentialité des données
              personnelles de nos utilisateurs. <br /> Nous respectons
              entièrement votre confidentialité. Vos informations personnelles
              recueillies ainsi que vos informations de paiement et autres
              contributions sont sécurisées et sauvegardées pour une durée
              précise. <br /> Les processus mis sur pied son cohérents et
              fiables. <br />
              Vous êtes assurés que votre financement sera destiné au projet
              pour lequel vous avez contribué. <br />
              Nos équipes veillent à répondre à toutes vos préoccupations et
              vous orientons sur les procédures d’accès et d’utilisation
              simplifiées du site afin de faciliter votre expérience
              utilisateur.
              <br /> Koud Pouss est une plateforme responsable. Nous mettons
              tout en œuvre pour assurer la clarté pendant tout le processus et
              nous nous assurons que tout utilisateur a la bonne information
              avant de s’engager.
            </p>
          </article>
          {/* Our goals */}
          <article>
            <h2 className="my-5 text-center text-2xl text-primary">
              Nos missions
            </h2>
            <p>
              Notre rôle est d’aider les porteurs de projets à donner vie à
              leurs envies et de garantir la sécurisation des transactions en
              ligne.
              <br /> Sur Koud Pouss, vous pouvez désormais compter sur la
              solidarité et le soutien de multiples acteurs pour la réalisation
              de vos projets. Collectez des dons en ligne en quelques clics.
              Notre rôle est d’aider les porteurs de projets à donner vie à
              leurs envies et de garantir la sécurisation des transactions en
              ligne. Sur Koud Pouss, vous pouvez désormais compter sur la
              solidarité et le soutien de multiples acteurs pour la réalisation
              de vos projets.
              <span className="my-10 block text-center font-bold text-primary ">
                Collectez des dons en ligne en quelques clics.
              </span>
            </p>
          </article>
        </section>
      </div>
    </div>
  );
};

export default About;
