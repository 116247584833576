import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import Banner from '../components/Banner';

const PrivacyPolicy = () => {
  return (
    <div className="container mx-auto max-w-7xl md:px-8 lg:px-24">
      <Helmet title="Bienvenue sur KOUDPOUSS" />
      <Banner
        image="happy_children.webp"
        currentPage={0}
        headerMessageContainer="items-end xl:ml-auto xl:mr-0 lg:text-left"
        bannerClass="h-banner-large"
      />
      <div className="bg-white">
        <section className="mx-auto max-w-5xl px-6 py-16 text-center text-xl leading-8">
          <h1 className="mb-16 text-2xl font-light text-black lg:text-xl">
            L’essentiel à savoir sur les traitements de vos données <br />{' '}
            personnelles par&nbsp;
            <span className="text-primary">Koud Pouss</span>
          </h1>
          <p className="text-center">
            La société <span className="font-bold text-black">Dream</span> Team
            online développe et exploite la plateforme{' '}
            <Link href="/">
              <span className="font-bold text-black">www.koud-pouss.com</span>
            </Link>{' '}
            dédiée au financement participatif. Elle met en relation des
            créateurs de projets (« porteurs de projets ») avec des personnes
            souhaitant soutenir des projets en y contribuant financièrement («
            contributeurs »). Dans le cadre de nos activités, nous sommes amenés
            à traiter des données personnelles vous concernant, que vous soyez
            simple visiteur du site Internet{' '}
            <Link href="/">
              <span className="font-bold text-black">www.koud-pouss.com</span>
            </Link>{' '}
            ou utilisateur (membre inscrit, contributeur ou porteur de projet).{' '}
            <br /> Le responsable des traitements des données est{' '}
            <span className="font-bold text-black">Dream</span> Team <br />
            online, société par actions simplifiées, immatriculée sous le numéro{' '}
            <br />
            903718153 au registre du commerce et des sociétés de Paris ayant son{' '}
            <br />
            siège social 101, Avenue du Général Leclerc 75685 Paris 14ème cedex
            14, France.
            <br />
            <span className="font-bold text-black">Dream</span> Team online est
            est immatriculée en tant qu’Intermédiaire en <br />
            Financement Participatif (IFP) sous le numéro 21009200. <br />
            Sur www.koud-pouss.com, nous collectons des données à caractère{' '}
            <br />
            personnel sur tous nos utilisateurs. Les données collectées par{' '}
            <br />
            l’intermédiaire du Site et ultérieurement traitées par{' '}
            <span className="font-bold text-black">Koud Pouss</span> sont celles
            que les Internautes transmettent volontairement
            <br /> notamment via les formulaires d’inscriptions. Ces données
            sont <br />
            collectées lors de l’utilisation de la rubrique « formulaire
            d’inscription <br />
            », et par l’utilisation de cookies conformément à la législation en
            vigueur. Cf. <span className="font-bold text-black">CGU</span>{' '}
            <br />
            Conformément à l’article 34 de la loi nº78-17 relative à
            l’informatique, <br />
            aux fichiers et aux libertés, l’internaute peut à tout moment
            accéder <br />
            aux informations personnelles le concernant ou peut en demander leur
            <br />
            rectification, leur complément, leur clarification, leur mise à jour
            ou <br />
            leur suppression par courriel au service. <br />
            client: contact@koud-pouss.com en indiquant ses noms, prénoms, date{' '}
            <br />
            et lieu de naissance, adresse postale et électronique.
            <br /> Certaines données vous concernant peuvent être combinées{' '}
            <br />
            ensemble. <br />
            Il peut s’agir des données que vous communiquez dans le cadre de{' '}
            <br />
            l’utilisation que vous faites de notre site <br />
            Internet www.koud-pouss.com et des données collectées par le biais
            de <br />
            cookies. <br />
            Certains traitements de données sont utilisés pour prendre des{' '}
            <br />
            décisions d’acceptation ou de refus d’un projet ou d’une
            <br />
            contribution. <br />
            Au titre de leurs obligations légales de lutte contre la fraude, le{' '}
            <br />
            blanchiment des capitaux et le financement du terrorisme,
            <span className="font-bold text-black">Koud Pouss</span> et ses
            prestataires de paiement (STRIPE)
            <br />
            effectuent des traitements, y compris des combinaisons de données,
            <br />
            pour aider leurs collaborateurs à déterminer le niveau de risque de
            <br />
            fraude associé à un paiement ou une collecte de fonds. Ces
            traitements
            <br />
            peuvent être utilisés pour justifier la décision de{' '}
            <span className="font-bold text-black">Koud Pouss</span> , STRIPE de
            refuser une collecte de fonds. <br />
            Vous pouvez être amenés à communiquer des données dites sensibles
            sur nos sites : <br />
            En publiant un projet, en remplissant votre profil utilisateur, en
            <br />
            participant à un projet, en publiant un commentaire, vous pouvez
            <br />
            révéler publiquement des données très personnelles (convictions
            <br />
            religieuses ou philosophiques, orientation sexuelle, opinions
            <br />
            politiques, état de santé, etc.). Nous vous invitons donc à la
            <br />
            prudence lors de la description de votre projet, de votre profil
            <br />
            utilisateur, lors de la rédaction de vos commentaires, ou lors de la
            <br />
            participation à un projet, afin de protéger votre vie privée. Il est
            <br />
            aussi possible, dans certains cas, de procéder à des contributions
            <br /> « anonymes » votre identité ne sera connue que par{' '}
            <span className="font-bold text-black">Koud Pouss</span> et par le
            porteur de projet concerné. <br />
            En tant que porteur de projet, vous pouvez être amenés à <br />
            communiquer des informations concernant des tiers (vos
            <br />
            collaborateurs, une personne que votre projet vise à aider, etc.).{' '}
            <br />
            Le traitement de ces données doit être fait dans le respect de la
            <br />
            réglementation. Vous devez vérifier que vous avez le droit de
            <br />
            communiquer ces données à Koud Pouss et au public (par exemple{' '}
            <br />
            en ayant l’accord écrit de ces tiers) et leur communiquer les
            <br />
            informations sur le traitement de leurs données par{' '}
            <span className="font-bold text-black">Koud Pouss</span>
            figurant dans la présente Politique de Confidentialité. <br />
            Certaines données doivent être obligatoirement collectées. <br />
            Nous avons besoin de certaines données pour vous fournir nos <br />
            services. Dès lors, en cas de collecte de données par le biais de{' '}
            <br />
            formulaires, le caractère obligatoire ou facultatif des réponses est{' '}
            <br />
            porté à votre connaissance sur chaque formulaire. Les conséquences{' '}
            <br />
            d’un défaut de réponse en cas de réponse obligatoire dépendent des{' '}
            <br />
            situations. Il s’agira principalement de l’impossibilité pour vous
            de <br />
            créer votre compte, de contribuer à un projet ou de proposer un
            projet, <br />
            de bénéficier de certains de nos services (l’exigence de fourniture
            est <br />
            alors contractuelle ou réglementaire lorsque la donnée exigée est
            liée à <br />
            nos obligations légales en tant que plateforme de financement
            <br />
            participatif) et de l’impossibilité pour nous de répondre à vos
            <br />
            demandes formées sur le fondement de vos droits (l’exigence de
            <br />
            fourniture est alors réglementaire).Les destinataires auxquels les
            données peuvent être <br />
            communiquées par Koud Pouss sont notamment nos sous-traitants,{' '}
            <br />
            les porteurs de projets, les partenaires dans le cas où votre projet{' '}
            <br />
            participe à une opération partenaire, ainsi que les autorités
            judiciaires <br />
            et administratives. <br />
            Vous disposez de droits. <br />
            Il s’agit du droit d’accès, du droit de rectification, du droit
            <br />
            d’effacement, du droit à la limitation des traitements, du droit
            <br />
            d’opposition au traitement de vos données, du droit de définir,
            <br />
            modifier et révoquer des directives relatives au sort de vos données
            <br />
            après votre mort, et du droit d’introduire une réclamation auprès de
            la <br />
            CNIL. Sous réserve de justifier de votre identité et de respecter
            les <br />
            conditions posées par la législation en vigueur, vous pourrez
            exercer <br />
            ces droits en nous écrivant à l’adresse électronique suivante :
            contact@koud-pouss.com ou à l’adresse postale <br />
            suivante : Dream team online 101, Avenue du Général Leclerc 75685{' '}
            <br />
            Paris 14 cedex 14, France.
            <br />
            Les durées de conservation de vos données varient en fonction de la
            <br />
            nature des données concernées, des finalités poursuivies ou encore
            <br />
            selon la durée de la prescription légale (qui est généralement de
            cinq
            <br />
            ans à compter du moment où la personne souhaitant exercer une action
            a eu ou aurait dû avoir connaissance des faits lui permettant de
            <br />
            l’exercer), sauf en cas de précontentieux ou de contentieux. <br />
            Vos données peuvent faire l’objet de transferts en dehors de <br />
            l’Union européenne. <br />
            Nous transférons certaines données à nos sous-traitants, partenaires
            ou <br />
            prestataires, tels que nos hébergeurs, outils de gestions d’envoi{' '}
            <br />
            d’emails, etc. En cas de transfert en dehors de l’Union européenne,{' '}
            <br />
            nous veillons à ce que vos données soient transférées vers des pays
            où <br />
            le niveau de protection des données personnelles a été reconnu comme{' '}
            <br />
            adéquat par la Commission européenne, ou à ce que les clauses <br />
            contractuelles types de la Commission européenne soient conclues{' '}
            <br />
            avec nos prestataires ou partenaires.
          </p>
          <h2 className="my-16 text-center text-2xl font-light text-black lg:text-xl">
            Politique de Confidentialité
          </h2>
          <p>
            La présente politique de confidentialité (la « Politique de
            Confidentialité ») définit les conditions dans lesquelles Dream Team
            online recueille et traite des données personnelles vous concernant
            en tant que visiteurs ou utilisateurs de la plateforme Koud Pouss,
            accessible à l’adresse
            <Link href="/">
              <span className="font-bold text-black">www.koud-pouss.com</span>
            </Link>{' '}
          </p>
          <p className="mx-auto max-w-2xl">
            Soucieux du respect de votre vie privée et de la protection des
            données vous concernant, nous respectons la législation en vigueur
            en matière de protection des données personnelles, et notamment la
            loi n°78-17 du 6 janvier 1978 dite loi « Informatique et Libertés »
            (la « loi Informatique et Libertés ») et le règlement européen
            2016/679 du 27 avril 2016 relatif à la protection des personnes
            physiques à l’égard du traitement des données à caractère personnel
            et à la libre circulation de ces données dit « Règlement général sur
            la protection des données » ou « RGPD » (le « RGPD »). Nous pouvons
            être conduits à mettre à jour la présente Politique de
            Confidentialité. Toute mise à jour significative sera préalablement
            portée à votre connaissance et, si cela est nécessaire, fera l’objet
            d’un recueil de votre consentement. 1. Quelles sont les données que
            nous collectons et traitons ? En notre qualité de responsable de
            traitement, nous collectons et traitons des données personnelles
            vous concernant a ce titre, nous collectons des données en votre
            qualité d’utilisateur de notre plateforme Koud Pouss afin de vous
            identifier et de vous contacter (nom, prénom, adresse email, adresse
            postale, téléphone) et de valider la mise en ligne de vos projets et
            d’organiser la collecte des contributions (informations sur votre
            profil, informations sur vos projets, informations bancaires, etc.).
            Nous collectons également des données relatives à votre utilisation
            de nos services, ainsi que des données de navigation sur nos sites
            Internet et nos pages dédiées sur les réseaux sociaux. À cette fin,
            nous collectons des données nécessaires à la réalisation de
            statistiques d’audience, de mesures de performance. Nous collectons
            des données relatives à vos échanges avec nous concernant nos
            services, et les demandes que vous nous présentez, notamment
            s’agissant de la gestion de vos droits et de vos directives en cas
            de décès. Enfin, nous collectons des données nous permettant de
            respecter nos obligations en matière de lutte contre la fraude, le
            blanchiment de capitaux et le financement du terrorisme ainsi que de
            gestion de vos droits, des précontentieux et du contentieux. Dans ce
            cadre, nous sommes amenés à collecter notamment des documents
            justifiant de votre identité. Toutes ces données peuvent nous être
            communiquées par vous-même lorsque vous remplissez nos formulaires
            ou lors de nos échanges. Elles peuvent également être recueillies
            par le biais d’outils informatiques tels que des cookies. Pour plus
            d’informations sur l’utilisation que nous faisons de ces outils,
            nous vous invitons à prendre connaissance de la politique de cookies
            accessible dans nos CGU. L’ensemble des données que nous collectons
            vous concernant peuvent être combinées entre elles afin de
            poursuivre les finalités décrites ci - après. <br />
            <span className="font-bold text-black">
              – DONNEES SENSIBLES
            </span>{' '}
            <br />
            Données sensibles et profil utilisateur : Les champs suivants de
            votre profil utilisateur sont destinés à vous permettre de
            personnaliser votre profil utilisateur et reste privé sur Koud Pouss
            : Nom, prénom, date de naissance, adresse, ville. Seul votre pseudo
            reste public. Données sensibles et pages projet / publication de
            news relative à votre projet : Lorsque vous décrivez un projet ou
            que vous publiez une news sur votre page projet, vous pourriez
            révéler des informations sur vous, parfois sensibles ou très
            personnelles (convictions religieuses ou philosophiques, orientation
            sexuelle, opinions politiques, état de santé, etc.). En cas de mise
            en ligne de votre projet ou de publication de votre news, ces
            informations sensibles deviendront publiques et accessibles à tous.
            Koud Pouss vous invite donc à la prudence lors de la description de
            votre projet et de la rédaction de vos news, pour protéger votre vie
            privée. Vous pouvez opter pour une collecte non publique ( privée ).
            Données sensibles et contribution à un projet : De manière générale,
            les contributions sont publiques et visibles depuis la liste des
            contributeurs d’un projet ou le profil utilisateur du membre
            contributeur. Le fait de contribuer à un projet peut parfois révéler
            des informations sensibles ou très personnelles. Vous pouvez opter
            pour un don ou un montant anonyme. Données sensibles et commentaires
            sur les projets ou autres publications : Lorsque vous écrivez un
            commentaire sur un projet ou une news publiée sur un projet, vous
            pourriez révéler des informations sur vous, parfois sensibles ou
            très personnelles (convictions religieuses ou philosophiques,
            orientation sexuelle, opinions politiques, état de santé, etc.). Une
            fois le formulaire de commentaire validé, ces informations sensibles
            deviendront publiques et accessibles à tous. Koud Pouss vous invite
            donc à la prudence lors de la rédaction de vos commentaires, pour
            protéger votre vie privée. – DONNEES DE TIERS En rédigeant la
            présentation d’un projet, vous pouvez être amenés à nous communiquer
            et à communiquer publiquement des informations concernant des tiers
            (vos collaborateurs, une personne que votre projet vise à aider,
            etc.) Le traitement de ces données doit être fait dans le respect de
            la réglementation. Vous devez vérifier que vous avez le droit de
            communiquer ces données à Koud Pouss et au public (par exemple en
            ayant l’accord écrit de ces tiers) et leur communiquer les
            informations sur le traitement de leurs données par Koud Pouss
            figurant dans la présente Politique de Confidentialité. 2. Quels
            sont les finalités et fondements juridiques de nos traitements de
            données ? En application de la réglementation, tout traitement de
            données à caractère personnel doit, pour être licite, reposer sur
            l’un des fondements juridiques énoncés à l’article 6 du RGPD ou à
            l’article 9.2 du RGPD s’il s’agit de données dites sensibles. Le
            tableau ci-dessous expose les différentes finalités pouvant être
            poursuivies lors de nos traitements de vos données et les fondements
            juridiques sur lesquels repose la poursuite de chacune de ces
            finalités.
          </p>
          <h2 className="my-16 text-center text-2xl font-light text-black lg:text-xl">
            Tableau
          </h2>
          <p>
            En application de la réglementation, tout traitement de données à
            caractère personnel doit, pour être licite, reposer sur l’un des
            fondements juridiques énoncés à l’article 6 du RGPD ou à l’article
            9.2 du RGPD s’il s’agit de données dites sensibles. <br />
            <br /> Le tableau ci-dessous expose les différentes finalités
            pouvant être poursuivies lors de nos traitements de vos données et
            les fondements juridiques sur lesquels repose la poursuite de
            chacune de ces finalités. Pour ce qui concerne l’encaissement des
            paiements effectués directement sur le site de Koud Pouss, il est
            géré par nos prestataires de paiement (STRIPE), agréés par
            l’Autorité de Contrôle Prudentiel et de Résolution ACPR. STRIPE
            effectuent des traitements de données personnelles dont ils sont
            responsables, décrits dans leurs politiques de confidentialités
            respectives.
            <br />
            <br /> Au titre de leurs obligations légales de lutte contre la
            fraude, le blanchiment des capitaux et le financement du terrorisme,
            Koud Pouss et STRIPE effectuent des traitements, y compris des
            combinaisons de données, pour aider leurs collaborateurs à
            déterminer le niveau de risque de fraude associé à un paiement ou
            une collecte de fonds. Ces traitements peuvent être utilisés pour
            justifier la décision de Koud Pouss ou STRIPE de refuser ou bloquer
            un paiement.
            <br />
            <br /> Lors de votre navigation sur notre site Internet
            www.koud-pouss.com, l’accès à certaines fonctionnalités nécessite la
            création d’un compte et donc la communication de certaines données.
            En créant un compte, vous devenez utilisateur de la plateforme Koud
            Pouss, ce qui vous permet, par la suite, de devenir porteur de
            projet ou contributeur. <br />
            <br />
            Afin de créer un compte sur le site www.koud-pouss.com vous devez
            obligatoirement renseigner un nom, prénom ou un pseudonyme, votre
            adresse électronique et un mot de passe. À défaut, vous ne pourrez
            pas vous inscrire.
            <br />
            <br /> En qualité de simple visiteur du site www.koud-pouss.com ,
            vous pourrez consulter certaines sections, mais ne pourrez déposer
            de projet ou lancer de collecte, ou même contribuer aux projets et
            collectes existants. Pour créer un projet sur le site
            www.koud-pouss.com, en plus des données obligatoirement renseignées
            lors de la création de votre compte, vous devez obligatoirement
            renseigner votre nom et votre prénom, le nom de votre projet, la
            catégorie dans laquelle il s’inscrit, sa description, votre pays de
            résidence, votre adresse de livraison, votre adresse de facturation,
            votre objectif, le montant et sa devise, vos coordonnées bancaires,
            et fournir tout justificatif d’identité nécessaire à votre
            identification selon que vous soyez une personne physique ou une
            personne morale. <br />
            <br />
            Afin de contribuer sur le site www.koud-pouss.com, en plus des
            données obligatoirement renseignées lors de la création de votre
            compte, vous devez obligatoirement renseigner votre nom et votre
            prénom, votre nationalité, votre pays de résidence, votre date de
            naissance, votre adresse de livraison et/ou de facturation si le
            porteur de projet a rendu cette information obligatoire afin de
            pouvoir vous livrer les contreparties choisies, vos informations de
            paiement carte bancaire, et fournir tout justificatif d’identité
            nécessaire à votre identification selon que vous soyez une personne
            physique ou une personne morale. <br />
            <br />
            En cas de non-communication de ces informations, vous ne pourrez pas
            publier votre projet ou contribuer aux projets présents sur le site
            www.koud-pouss.com. Ces exigences de fourniture de données ont ici
            un caractère contractuel et/ou réglementaire (notamment pour votre
            justificatif d’identité). <br />
            <br />
            Afin d’exercer vos droits, tels que rappelés plus haut, la
            communication d’un justificatif d’identité sera requise. La
            non-communication peut nous empêcher de répondre à vos demandes.
            Cette exigence de fourniture de données a ici un caractère
            réglementaire. <br />
            <br />
            La collecte d’autres données peut être obligatoire. Toutes les
            données dont la collecte est obligatoire, la nature contractuelle ou
            réglementaire de cette obligation de fourniture, ainsi que les
            conséquences de l’absence de fourniture des données concernées sont
            alors portées à votre connaissance directement lors de la collecte.{' '}
            <br />
            <br />
            3. La collecte et le traitement de vos données sont-ils obligatoires
            ?
            <br />
            <br /> Lors de votre navigation sur notre site Internet
            www.koud-pouss.com, l’accès à certaines fonctionnalités nécessite la
            création d’un compte et donc la communication de certaines données.
            En créant un compte, vous devenez utilisateur de la plateforme Koud
            Pouss, ce qui vous permettant, par la suite, de devenir porteur de
            projet ou contributeur. <br />
            <br />
            Afin de créer un compte sur le site www.koud-pouss.com vous devez
            obligatoirement renseigner un nom, prénom ou un pseudonyme, votre
            adresse électronique et un mot de passe. À défaut, vous ne pourrez
            pas vous inscrire. En qualité de simple visiteur du site
            www.koud-pouss.com , vous pourrez consulter certaines sections, mais
            ne pourrez déposer de projet ou lancer de collecte, ou même
            contribuer aux projets et collectes existants. Pour créer un projet
            sur le site www.koud-pouss.com, en plus des données obligatoirement
            renseignées lors de la création de votre compte, vous devez
            obligatoirement renseigner votre nom et votre prénom, le nom de
            votre projet, la catégorie dans laquelle il s’inscrit, sa
            description, votre pays de résidence, votre adresse de livraison,
            votre adresse de facturation, votre objectif, le montant et sa
            devise, vos coordonnées bancaires, et fournir tout justificatif
            d’identité nécessaire à votre identification selon que vous soyez
            une personne physique ou une personne morale. <br />
            <br />
            Afin de contribuer sur le site www.koud-pouss.com, en plus des
            données obligatoirement renseignées lors de la création de votre
            compte, vous devez obligatoirement renseigner votre nom et votre
            prénom, votre nationalité, votre pays de résidence, votre date de
            naissance, votre adresse de livraison et/ou de facturation si le
            porteur de projet a rendu cette information obligatoire afin de
            pouvoir vous livrer les contreparties choisies, vos informations de
            paiement carte bancaire, et fournir tout justificatif d’identité
            nécessaire à votre identification selon que vous soyez une personne
            physique ou une personne morale. En cas de non-communication de ces
            informations, vous ne pourrez pas publier votre projet ou contribuer
            aux projets présents sur le site www.koud-pouss.com. Ces exigences
            de fourniture de données ont ici un caractère contractuel et/ou
            réglementaire (notamment pour votre justificatif d’identité). Afin
            d’exercer vos droits, tels que rappelés plus haut, la communication
            d’un justificatif d’identité sera requise. La non-communication peut
            nous empêcher de répondre à vos demandes. Cette exigence de
            fourniture de données a ici un caractère réglementaire. La collecte
            d’autres données peut être obligatoire. Toutes les données dont la
            collecte est obligatoire, la nature contractuelle ou réglementaire
            de cette obligation de fourniture, ainsi que les conséquences de
            l’absence de fourniture des données concernées sont alors portées à
            votre connaissance directement lors de la collecte. <br />
            <br />
            4. A qui sommes-nous susceptibles de transmettre vos données ?{' '}
            <br />
            <br />
            <br />
            <br />
            Nous pouvons être amenés à communiquer des données : • A nos
            sous-traitants (hébergeurs, outils d’envois de mailing, outil de
            sécurité des sites, gestionnaire de formulaires, outils de mesures
            d’audience, outil de partage de documents, outil de traçage de la
            navigation sur nos sites, outil de service client et assistance,
            outil de ciblage publicitaires sur les réseaux sociaux et Internet).
            Nos sous-traitants sont tenus d’une obligation de confidentialité et
            de sécurité, ainsi que d’autres obligations énumérées dans le RGPD.
            Nous choisissons avec soin nos sous-traitants et demeurons en tout
            état de cause responsables du traitement de vos données à caractère
            personnel par nos sous-traitants ; <br />
            <br />• Au porteur de projet lorsque vous contribuez à un projet.
            Koud Pouss communique à celui-ci des informations vous concernant
            afin que celui-ci puisse échanger avec vous et que le montant prévu
            vous soit reversé. Ces données sont respectivement, votre email, nom
            d’utilisateur, le montant payé, date et mode de paiement, Si vous
            les avez communiqués nous lui fournissons également vos noms,
            prénoms, adresse. <br />
            <br />• A nos partenaires et prestataires de services de paiement en
            ligne (STRIPE) afin d’assurer la réalisation effective des collectes
            de fonds, la gestion des contributions à un projet, et leur
            permettre de remplir leurs propres diligences et obligations en
            matière de lutte contre la fraude, le blanchiment et le financement
            du terrorisme ; • Aux organismes et autorités publics, auxiliaires
            de justice, personnes chargées du contrôle financier (commissaires
            aux comptes, expert-comptable), entités chargées de la lutte contre
            la fraude, le blanchiment de capitaux et le financement du
            terrorisme, et ce exclusivement afin de répondre à nos obligations
            légales ; Sous réserve de ce qui précède, nous nous engageons à ne
            jamais divulguer vos données à des tiers, sauf avec votre
            autorisation expresse ou dans des circonstances très particulières,
            et notamment : <br />
            <br />• Si nous sommes amenés, du fait de la loi, dans le cadre
            d’une procédure judiciaire, d’un litige et/ou d’une requête émanant
            des autorités publiques, à divulguer vos données ; <br />
            <br />• Si nous pensons qu’à des fins de sécurité nationale,
            d’application de la loi ou autre motif d’intérêt général, la
            divulgation est nécessaire ou appropriée. <br />
            <br />
            5. Quels sont les droits dont vous disposez ? <br />
            <br />
            <br />
            <br />
            Dans les conditions définies aux articles 15 et suivants du RGPD,
            vous disposez, sauf exceptions : <br />
            <br />• Du droit d’obtenir de Koud Pouss la confirmation que des
            données personnelles vous concernant sont en sécurité ou ne sont pas
            traitées de manière malveillante et que vous pouvez y avoir accès à
            tout moment en vertu du (droit d’accès – article 15 du RGPD) ;{' '}
            <br />• Du droit d’obtenir de Koud Pouss la rectification des
            données personnelles vous concernant qui sont inexactes (droit de
            rectification – article 16 du RGPD) ; <br />• Du droit d’obtenir de
            Koud Pouss l’effacement de données personnelles vous concernant,
            dans certains cas (droit d’effacement ou « droit à l’oubli » –
            article 17 du RGPD) ; <br />• Du droit d’obtenir de Koud Pouss la
            limitation des traitements, dans certains cas (droit à la limitation
            du traitement – article 18 du RGPD) ; <br />• Du droit d’obtenir à
            tout moment de Koud Pouss, pour de raisons tenant à votre situation
            particulière, que nous ne procédions plus aux traitements des
            données personnelles vous concernant, dans certains cas (droit
            d’opposition – article 21.1 du RGPD) <br />; • Du droit de vous
            opposer, à tout moment, au traitement des données personnelles vous
            concernant à des fins de prospection (droit d’opposition à la
            prospection – article 21.2 du RGPD) ; <br />• Du droit d’introduire
            une réclamation auprès de l’autorité de protection des données
            compétentes dans votre État membre ou auprès de la Commission
            Nationale de l’Informatique et des Libertés (la CNIL), autorité de
            protection des données française ; <br />• Du droit de définir,
            modifier et révoquer à tout moment des directives relatives à la
            conservation, à l’effacement et à la communication de vos données
            personnelles après votre mort, en application de l’article 40-1 de
            la loi Informatique et Libertés. Ces directives peuvent être
            générales ou particulières. Nous pouvons être uniquement
            dépositaires des directives particulières concernant les données que
            nous traitons, les directives générales peuvent être recueillies et
            conservées par un tiers de confiance numérique certifié par la CNIL.{' '}
            <br />
            <br />
            Vous avez également le droit de désigner un tiers auquel les données
            vous concernant pourront être communiquées après votre mort. Vous
            vous engagez alors à informer ce tiers de votre démarche et du fait
            que des données permettant de l’identifier sans ambiguïté nous
            seront transmises, à lui communiquer la présente Politique de
            Confidentialité. <br />
            <br />
            Sous réserve de justifier de votre identité et des éléments
            susvisés, vous pouvez exercer vos droits en nous écrivant soit à
            contact@koud-pouss.com ou à l’adresse postale suivante : Koud Pouss
            101, Avenue du Général Leclerc 75685 Paris 14 cedex 14 Paris,
            France. Si vous disposez d’un compte sur le site www.koud-pouss.com,
            il vous est possible de procéder à certaines rectifications ou
            suppressions directement à partir de compte utilisateur. <br />
            <br />
            Par ailleurs, vous pouvez nous contacter à l’adresse électronique ou
            à l’adresse postale précédemment mentionnées pour toute question
            relative à la Politique de Confidentialité et aux traitements de vos
            données. <br />
            <br />À l’issue des durées listées ci-dessus, vos données feront
            l’objet soit d’une suppression, soit d’une anonymisation. <br />
            <br />
            Par exception aux paragraphes précédents, en cas de contentieux,
            toutes ou certaines de vos données pourront faire l’objet d’une
            conservation prolongée si celles-ci s’avèrent utiles pour ledit
            contentieux. <br />
            <br />
            7. Sommes-nous susceptibles de transférer vos données en dehors de
            l’Espace Économique Européen ? <br />
            <br />
            <br />
            <br />
            Dans le cadre des services que nous vous proposons et des finalités,
            nous avons recours à plusieurs sous-traitants ou prestataires
            auxquels nous avons besoin de transférer tout ou partie des données
            que nous collectons et traitons. Les serveurs de certains de nos
            sous-traitants sont situés en dehors de l’Union européenne, vos
            données sont alors amenées à être transférées en dehors de l’Espace
            Économique Européen. Dans le cas du transfert de vos données en
            dehors de l’Espace Économique Européen, Koud Pouss veille à prendre
            toute précaution utile. Elle s’assure, notamment, que le transfert
            de vos données est opéré vers des pays dont le niveau de protection
            des données à caractère personnel a été reconnu comme adéquat par la
            Commission Européenne, ou, à défaut que les clauses contractuelles
            types de ladite Commission soient conclues.
            <br />
            <br />
            Sur simple demande à nous formuler, nous pouvons vous fournir
            davantage d’informations quant à ces transferts (notamment les
            clauses contractuelles types de la Commission Européenne). <br />
            <br />
            8. Qui est votre interlocuteur chez Koud Pouss pour répondre à vos
            questions sur les données personnelles ? <br />
            <br />
            Pour toutes questions relatives au traitement de vos données
            personnelles des interlocuteurs sont à votre disposition. Ils
            peuvent être contactés soit à contact@koud-pouss.com ou à l’adresse
            postale suivante : Koud Pouss 101, Avenue du Général Leclerc 75685
            Paris 14 cedex 14 Paris, France.
          </p>
          <table className="mx-auto max-w-2xl border-collapse border border-black">
            <thead className="font-bold">
              <tr>
                <th>finalités poursuivies</th>
                <th>Fondements juridiques</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  Gérer les visiteurs du site web{' '}
                  <Link href="/">
                    <span className="font-bold text-sky-600">
                      www.koud-pouss.com
                    </span>
                  </Link>
                </td>
                <td>
                  Mesures précontractuelles <br /> Interêts légitimes des
                  visiteurs à disposer d&#39;un affichage <br />
                  du site web{' '}
                  <Link href="/">
                    <span className="font-bold text-sky-600">
                      www.koud-pouss.com
                    </span>
                  </Link>{' '}
                  dans de bonnes conditions
                </td>
              </tr>
            </tbody>
          </table>
        </section>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
