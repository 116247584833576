/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable no-unused-vars */
import { TOKEN } from 'api';
import { confirmAccount } from 'api/user';
import Header from 'components/Header';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation, useNavigate } from 'react-router-dom';

const Confirm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [token, setToken] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (location.pathname.split('/')[2]) {
      setToken(location.pathname.split('/')[2]);
    }
  }, []);

  useEffect(() => {
    if (token) {
      confirmAccount(token)
        .then(() => {
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
          setError(true);
        });
    }
  }, [token]);

  const login = (e) => {
    e.preventDefault();

    if (!loading && TOKEN) {
      navigate('/');
    } else {
      navigate('/se-connecter');
    }
  };

  return (
    <div className="container mx-auto max-w-7xl md:px-8 lg:px-24">
      <Header />
      <Helmet title="Confirmer le compte - KOUDPOUSS" />
      <div className="m-auto h-full w-full items-center justify-center bg-gray-50 py-24">
        {!loading && !error && (
          <img
            src="/assets/success.svg"
            alt="logo"
            className="absolute left-[40%] mx-auto block w-24 sm:left-[44%] md:left-[45%] lg:left-[46%] xl:left-[47%] 2xl:left-[48%]"
          />
        )}

        {!loading && error && (
          <img
            src="/assets/error.svg"
            alt="logo"
            className="absolute left-[40%] mx-auto block w-24 sm:left-[44%] md:left-[45%] lg:left-[46%] xl:left-[47%] 2xl:left-[48%]"
          />
        )}
        <div className="m-auto flex h-full w-full max-w-xl flex-col items-center justify-center space-y-10 rounded px-4 py-8 lg:px-24">
          <div className="w-full space-y-6">
            <div className="relative text-center">
              <div
                className={`mx-auto text-center transition-colors
                ${loading ? 'text-primary' : 'text-transparent'}`}
              >
                <svg
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-live="polite"
                  aria-busy="true"
                  aria-labelledby="title-08a desc-08a"
                  className="mx-auto h-16 w-16 md:h-24 md:w-24"
                >
                  <title id="title-08a">loading</title>
                  <path
                    d="M7 8H3V16H7V8Z"
                    className="animate animate-bounce fill-current "
                  />
                  <path
                    d="M14 8H10V16H14V8Z"
                    className="animate animate-bounce fill-current [animation-delay:.2s]"
                  />
                  <path
                    d="M21 8H17V16H21V8Z"
                    className="animate animate-bounce fill-current [animation-delay:.4s]"
                  />
                </svg>
              </div>
            </div>

            <h1 className="text-center text-lg font-bold leading-10 md:text-xl">
              {loading && 'Veuillez patienter...'}
              {!loading && !error && 'Votre compte est maintenant confirmé !'}
              {!loading &&
                error &&
                "Votre compte n'a pas pu être confirmé en raison de lien expiré ou invalide ! Veuillez réessayer."}
            </h1>

            <div
              className={`flex flex-col items-center justify-center space-y-5
          ${loading || error ? 'hidden' : 'block'}`}
            >
              <button
                type="button"
                className="relative rounded-xl bg-primary px-3 py-2 text-white"
                onClick={login}
              >
                Se connecter
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Confirm;
