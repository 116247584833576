import Header from 'components/Header';
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { TOKEN } from '../api';
import { request } from '../api/user';

const ReinitialiserSchema = Yup.object().shape({
  email: Yup.string().email('Email incorrect').required('Email obligatoire'),
});

const Reinitialiser = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState([]);

  const connect = (user) => {
    setLoading(true);
    request(user)
      .then(() => {
        setMessage([
          true,
          'Vérifiez votre boîte de réception pour réinitialiser votre mot de passe.',
        ]);
        setLoading(false);
      })
      .catch((err) => {
        if (err.response.status === 404) {
          setMessage([false, 'Aucun compte trouvé avec cet email.']);
        } else {
          setMessage([false, 'Une erreur s’est produite. Veuillez réessayer.']);
        }
        setLoading(false);
      });
  };

  useEffect(() => {
    if (TOKEN) navigate('/');
  }, [TOKEN]);

  return (
    <div className="container mx-auto max-w-7xl md:px-8 lg:px-24">
      <Header />
      <Helmet title="Réinitialiser - KOUDPOUSS" />
      <section className="bg-white">
        <div className="flex min-h-screen justify-center p-8 md:p-16 lg:p-24">
          <div className="hidden rounded-l-[60px] bg-secondary/25 bg-cover bg-no-repeat bg-blend-multiply lg:block lg:w-2/5">
            <div className="-ml-24 flex h-full flex-col justify-center">
              <img src="/assets/logo-.png" className="w-2/3" alt="logo" />
            </div>
          </div>

          <div className="mx-auto flex w-full items-center p-8 lg:w-4/5 lg:px-12">
            <div className="w-full">
              <div className="flex h-full flex-row justify-center">
                <img
                  src="/assets/red-envelope.png"
                  className="w-auto scale-110"
                  alt="logo"
                />
              </div>

              <div className="mx-auto mt-4 max-w-md text-center">
                <h1 className="mt-16 text-2xl font-bold text-black">
                  Réinitialisez votre mot de passe
                </h1>
                <br />

                <Formik
                  initialValues={{
                    email: '',
                  }}
                  validationSchema={ReinitialiserSchema}
                  onSubmit={(values) => {
                    if (!loading) {
                      connect(values);
                    }
                  }}
                  classNam="w-full"
                >
                  {({ errors, touched }) => (
                    <Form className="flex w-full flex-col space-y-4">
                      <Field
                        name="email"
                        type="email"
                        placeholder="Entrez votre mail"
                        className="relative mx-auto w-full items-start justify-center rounded-full bg-secondary p-6 text-base leading-tight text-white placeholder:text-white"
                      />
                      {errors.email && touched.email ? (
                        <div className="text-red-400">{errors.email}</div>
                      ) : null}

                      {errors.password && touched.password ? (
                        <div className="text-red-400">{errors.password}</div>
                      ) : null}

                      <br />
                      {message.length > 0 && (
                        <div
                          className={`mb-4 text-center text-sm ${
                            message[0] ? 'text-primary' : 'text-red-500'
                          }`}
                        >
                          {message[1]}
                        </div>
                      )}
                      <button
                        type="submit"
                        className={`relative mx-auto w-fit items-start justify-center rounded-full bg-secondary px-9 py-4 text-base font-bold leading-tight text-white
                      ${!loading && 'duration-300 hover:scale-105'}`}
                      >
                        {loading ? 'En cours' : 'Réinitialiser'}
                      </button>
                    </Form>
                  )}
                </Formik>
                <br />
                <div className="flex flex-col items-center justify-center space-y-5 sm:flex-row sm:justify-between">
                  <a
                    href="/se-connecter"
                    className="text-center text-base leading-tight text-[#707070] hover:underline"
                  >
                    Se connecter
                  </a>
                  <a
                    href="/creer-un-compte"
                    className="text-center text-base leading-tight text-[#707070] hover:underline"
                  >
                    S’enregistrer
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Reinitialiser;
