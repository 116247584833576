/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable no-unused-vars */
import { deleteCollect, getUserCollect, updateCollect } from 'api/collection';
import Header from 'components/Header';
import IconSimpleDownload from 'components/icons/IconSimpleDownload';
import IconTrash from 'components/icons/IconTrash';
import InternalEditor from 'components/InternalEditor';
import SubHeader from 'components/SubHeader';
import { fr } from 'date-fns/locale';
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { Calendar } from 'react-date-range';
import { Helmet } from 'react-helmet';
import ReactCrop from 'react-image-crop';
import ReactPlayer from 'react-player';
import { useLocation, useNavigate } from 'react-router-dom';
import useOutsideClick from 'utils/useOutsideClick';
import * as Yup from 'yup';

const isValidUrl = (urlString) => {
  try {
    return Boolean(new URL(urlString));
  } catch {
    return false;
  }
};

const CollectionSchema = Yup.object().shape({
  name: Yup.string()
    .required('Veuillez entrer un nom pour la collecte')
    .min(5, 'Le titre de la collecte doit contenir au moins 5 caractères'),
  amountToCollect: Yup.mixed().when('hasAmountToCollect', {
    is: true,
    then: (schema) => schema.nullable(),
    otherwise: (schema) =>
      Yup.number()
        .transform((value) => (Number.isNaN(value) ? undefined : value))
        .min(20, 'Le montant collecté doit être supérieure à 20€')
        .required('Veuillez entrer un montant'),
  }),
  endingDate: Yup.string().when('hasEndingDate', {
    is: true,
    then: (schema) => schema.required('Veuillez précisez une date'),
    otherwise: (schema) => schema.notRequired(),
  }),
  videoLink: Yup.string().when('hasVideo', {
    is: true,
    then: (schema) =>
      schema.test('validUrl', 'Veuillez saisir un lien vidéo valide', (value) =>
        value ? isValidUrl(value) : false
      ),
    otherwise: (schema) => schema.notRequired(),
  }),
});

const Editcollection = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const slug = location.pathname?.split('/')[2]?.toLowerCase();
  const [collection, setCollection] = useState({});
  const [content, setContent] = useState(null);
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState('');
  const [isUpdating, setIsUpdating] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [message, setMessage] = useState([]);
  const [endingDate, setEndingDate] = useState(new Date());
  const [showCalendar, setShowCalendar] = useState(false);
  const [fixedAmountToCollect, setFixedAmountToCollect] = useState(true);
  const [addEndingDate, setAddEndingDate] = useState(false);
  const [isPublic, setIsPublic] = useState(true);
  const [isAmountPublic, setIsAmountPublic] = useState(true);
  const formRef = useRef(null);
  const [resizedImage, setResizedImage] = useState(null);
  const [imageData, setImageData] = useState(null);
  const [crop, setCrop] = useState({
    unit: '%',
    x: 10,
    y: 10,
    width: 80,
    height: 80,
  });
  const [croppedImageUrl, setCroppedImageUrl] = useState('');
  const [addVideo, setAddVideo] = useState(false);
  const [preview, setPreview] = useState(null);
  const [validVideo, setValidVideo] = useState(true);

  const redirect = () => {
    const newLocation = window.location.href;
    setTimeout(() => {
      navigate(`/se-connecter?redirect=${newLocation}`);
    }, 500);
  };

  const logOut = () => {
    localStorage.removeItem('persist:root');
    redirect();
  };

  const openCalendar = () => {
    setShowCalendar(true);
  };

  const closeCalendar = () => {
    setShowCalendar(false);
  };

  const calendarDateRef = useOutsideClick(closeCalendar);

  const fetchCollection = () => {
    setLoading(true);
    getUserCollect(slug)
      .then((res) => {
        setCollection(res?.data?.data);
        setContent(JSON.parse(res?.data?.data?.description));
        setImage(res?.data?.data.image);
        setLoading(false);

        if (
          res?.data?.data?.url_video &&
          res?.data?.data?.url_video !== 'null'
        ) {
          setPreview(true);
          setAddVideo(true);
        }

        if (res?.data?.data?.end_date) {
          setEndingDate(new Date(res?.data?.data?.end_date));
          setAddEndingDate(true);
        }

        if (
          res?.data?.data?.montant_cagnotte === null ||
          res?.data?.data?.montant_cagnotte === 0
        ) {
          setFixedAmountToCollect(true);
        } else {
          setFixedAmountToCollect(false);
        }

        if (res?.data?.data?.is_active === 0) {
          setIsPublic(false);
        }

        if (res?.data?.data?.is_public_collect_amount === 0) {
          setIsAmountPublic(false);
        }
      })
      .catch((err) => {
        if (err?.response?.status === 403) {
          logOut();
        }
        setMessage([false, 'Une erreur s’est produite. Veuillez réessayer.']);
        setLoading(false);
      });
  };

  const canDefine = (e, which) => {
    if (which === 'amountToCollect') {
      setFixedAmountToCollect(e.target.checked);
    } else {
      setAddEndingDate(e.target.checked);
    }
  };

  useEffect(() => {
    if (slug) fetchCollection();
  }, [slug]);

  const getCroppedImg = (sourceImage, cr, filename) => {
    if (!sourceImage) {
      console.error('Source image is null or undefined');
      return Promise.resolve(null);
    }

    const canvas = document.createElement('canvas');
    const scaleX = sourceImage.naturalWidth / sourceImage.width;
    const scaleY = sourceImage.naturalHeight / sourceImage.height;
    canvas.width = cr.width;
    canvas.height = cr.height;
    const ctx = canvas.getContext('2d');

    if (!ctx) {
      console.error('Failed to get 2D context');
      return Promise.resolve(null);
    }

    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        try {
          ctx.drawImage(
            img,
            cr.x * scaleX,
            cr.y * scaleY,
            cr.width * scaleX,
            cr.height * scaleY,
            0,
            0,
            cr.width,
            cr.height
          );

          canvas.toBlob((blob) => {
            if (blob) {
              setResizedImage(blob);
              resolve(URL.createObjectURL(blob));
            } else {
              console.error('Failed to create blob from canvas');
              reject(new Error('Failed to create blob from canvas'));
            }
          }, formRef.current?.values?.file?.type || 'image/png');
        } catch (error) {
          console.error('Error cropping image:', error);
          reject(error);
        }
      };
      img.onerror = () => {
        reject(new Error('Failed to load image'));
      };
      img.crossOrigin = 'anonymous';
      img.src = sourceImage.src;
    });
  };

  const makeClientCrop = async (cropp) => {
    if (imageData && cropp.width && cropp.height) {
      const croppedImg = await getCroppedImg(
        imageData,
        cropp,
        formRef.current?.values?.file.name
      );
      setCroppedImageUrl(croppedImg);
    }
  };

  const handleDeleteCollect = async () => {
    if (window.confirm('Êtes-vous sûr de vouloir supprimer cette collecte ?')) {
      try {
        setIsDeleting(true);
        await deleteCollect(collection.id);
        setMessage([true, 'Votre collecte a été supprimée avec succès']);

        setTimeout(() => {
          navigate('/mes-collectes');
        }, 2000);
      } catch (err) {
        if (err?.response?.status === 403) {
          logOut();
        } else {
          setIsDeleting(false);
          setMessage([
            false,
            "Une erreur s'est produite lors de la suppression. Veuillez réessayer.",
          ]);
        }
      }
    }
  };

  return (
    <div className="container mx-auto max-w-7xl md:px-8 lg:px-24">
      <Helmet title="Modifier ma collecte - KOUDPOUSS" />

      <Header />
      <section className="bg-white px-6 pb-20 pt-6">
        {/* Menus */}
        <SubHeader currentPage={2} />

        <h1 className="mb-4 text-center text-2xl text-primary">
          Modifier ma collecte
        </h1>

        {loading && (
          <div className="relative text-center">
            <div
              className={`mx-auto text-center transition-colors delay-300
                ${loading ? 'text-primary' : 'text-transparent'}`}
            >
              <svg
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                aria-live="polite"
                aria-busy="true"
                aria-labelledby="title-08a desc-08a"
                className="mx-auto h-16 w-16 md:h-24 md:w-24"
              >
                <title id="title-08a">loading</title>
                <path
                  d="M7 8H3V16H7V8Z"
                  className="animate animate-bounce fill-current "
                />
                <path
                  d="M14 8H10V16H14V8Z"
                  className="animate animate-bounce fill-current [animation-delay:.2s]"
                />
                <path
                  d="M21 8H17V16H21V8Z"
                  className="animate animate-bounce fill-current [animation-delay:.4s]"
                />
              </svg>
            </div>
          </div>
        )}
        {/* My Collection */}
        {!loading && (
          <section>
            <Formik
              innerRef={formRef}
              initialValues={{
                name: collection?.title,
                file: '',
                description: collection?.description,
                endingDate: collection?.end_date
                  ? collection?.end_date?.split('T')[0]
                  : '',
                videoLink:
                  collection?.url_video && collection?.url_video !== 'null'
                    ? collection?.url_video
                    : '',
                hasEndingDate: collection?.end_date !== null,
                hasAmountToCollect:
                  collection?.montant_cagnotte === 0 ||
                  collection?.montant_cagnotte === null,
                amountToCollect:
                  collection?.montant_cagnotte === 0
                    ? ''
                    : collection?.montant_cagnotte,
                linkName: collection?.slug,
                hasVideo:
                  collection?.url_video && collection?.url_video !== 'null',
                isPublic: collection?.is_active === 1,
                isAmountPublic: collection?.is_public_collect_amount === 1,
              }}
              validationSchema={CollectionSchema}
              onSubmit={(values, { setErrors, resetForm, setFieldValue }) => {
                const errors = {};
                const formData = new FormData();

                // append all values in collection
                Object.keys(collection).forEach((key) => {
                  if (
                    key !== 'users_id' &&
                    key !== 'category_name' &&
                    key !== 'slug' &&
                    key !== 'image' &&
                    key !== 'url_video' &&
                    key !== 'category_id' &&
                    key !== 'created_at' &&
                    key !== 'updated_at'
                  )
                    formData.append(key, collection[key]);
                });

                formData.set('title', values?.name);
                formData.set('is_active', values.isPublic ? 1 : 0);
                formData.set(
                  'is_public_collect_amount',
                  values.isAmountPublic ? 1 : 0
                );
                // blob to File
                const newFile = new File([resizedImage], values.file.name, {
                  type: values.file.type,
                });

                if (values.file !== '') {
                  if (resizedImage) formData.append('collecteimg', newFile);
                  else formData.append('collecteimg', values.file);
                }

                if (values.videoLink && values.videoLink.trim() !== '') {
                  formData.set('url_video', values.videoLink);
                } else {
                  formData.set('url_video', null);
                }

                if (values.hasAmountToCollect) {
                  formData.set('montant_cagnotte', 0);
                  formData.set('montant_is_libre', 1);
                } else {
                  formData.set('montant_cagnotte', values.amountToCollect);
                  formData.set('montant_is_libre', 0);
                }

                if (values.hasEndingDate && values.endingDate.length === 0) {
                  setErrors({
                    ...errors,
                    endingDate: 'Veuillez précisez une date',
                  });
                  return;
                }

                if (values.hasEndingDate && values.endingDate) {
                  formData.set('end_date', values.endingDate);
                } else {
                  formData.set('end_date', '');
                }
                if (values.description) {
                  formData.set(
                    'description',
                    JSON.stringify(values.description, null, 4)
                  );
                }

                setIsUpdating(true);
                updateCollect(formData)
                  .then(() => {
                    setIsUpdating(false);
                    setMessage([true, 'Votre collecte a été mise à jour']);

                    setTimeout(() => {
                      navigate(`/collecte/${collection.slug}`);
                    }, 2500);
                  })
                  .catch((err) => {
                    if (err.response.status === 403) {
                      setTimeout(() => {
                        logOut();
                      }, 300);
                    } else {
                      setIsUpdating(false);
                      setMessage([
                        false,
                        'Une erreur s’est produite. Veuillez réessayer.',
                      ]);
                    }
                  });
              }}
              className="w-full"
            >
              {({ errors, touched, setFieldValue }) => (
                <Form
                  className="mx-auto flex w-full max-w-4xl flex-col space-y-4 p-4 md:p-5"
                  id="form"
                >
                  <figure>
                    <label htmlFor="file" className="mb-6 flex justify-center">
                      <div className="relative flex space-x-4 rounded border border-slate-300 p-4">
                        <IconSimpleDownload />
                        <span>Importer une nouvelle image</span>
                      </div>
                      <input
                        name="image"
                        type="file"
                        id="file"
                        accept="image/png, image/jpeg, image/jpg, image/webp"
                        onChange={(event) => {
                          const file = event.currentTarget.files[0];
                          setImage(URL.createObjectURL(file));
                          setFieldValue('file', file);
                          setFieldValue('videoLink', '');
                          setAddVideo(false);
                          setFieldValue('hasVideo', false);
                          setValidVideo(true);
                        }}
                        className="hidden"
                      />
                    </label>
                    {image &&
                      !formRef.current?.values?.videoLink &&
                      (collection?.url_video === 'null' ||
                        collection?.url_video === null) && (
                        <p className="mb-6 text-center text-red-500">
                          Vous pouvez recadrer votre image
                        </p>
                      )}

                    {!image &&
                      !formRef.current?.values?.videoLink &&
                      (collection?.url_video === 'null' ||
                        collection?.url_video === null) && (
                        <img
                          alt="default"
                          style={{ maxWidth: '100%' }}
                          src={collection?.category_info?.original?.data?.image}
                        />
                      )}

                    {image &&
                      !formRef.current?.values?.videoLink &&
                      !addVideo && (
                        <div className="mx-auto max-w-md">
                          <ReactCrop
                            src={image}
                            crop={crop}
                            ruleOfThirds
                            onImageLoaded={(img) => {
                              setImageData(img);
                            }}
                            onComplete={(cropped) =>
                              image ? makeClientCrop(cropped) : null
                            }
                            onChange={(cropData) => setCrop(cropData)}
                          />

                          {croppedImageUrl && (
                            <img
                              alt="Crop"
                              style={{ maxWidth: '100%' }}
                              src={croppedImageUrl}
                            />
                          )}
                        </div>
                      )}

                    <span className="my-6 block text-center">Ou</span>

                    {formRef.current?.values?.videoLink &&
                      preview &&
                      addVideo && (
                        <div className="mx-auto max-w-md">
                          <ReactPlayer
                            width="100%"
                            height={300}
                            url={formRef.current?.values?.videoLink}
                            onError={() => {
                              setPreview(false);
                              setValidVideo(false);
                            }}
                          />
                        </div>
                      )}

                    {formRef.current?.values?.videoLink && !validVideo && (
                      <p className="mb-6 text-center text-red-500">
                        Nous n&apos;arrivons pas à charger votre vidéo. Le lien
                        n&apos;est pas valide ou ne fonctionne pas
                      </p>
                    )}
                  </figure>

                  <div className="my-5 flex items-start">
                    <span className="mr-2 block w-full grow">
                      Utiliser une vidéo à la place
                    </span>
                    <div>
                      <label
                        htmlFor="video_a_utiliser"
                        className="relative inline-flex cursor-pointer items-center"
                      >
                        <input
                          type="checkbox"
                          checked={addVideo}
                          onChange={(e) => {
                            setFieldValue('hasVideo', e.target.checked);
                            setAddVideo(e.target.checked);
                            if (!e.target.checked) {
                              setFieldValue('videoLink', '');
                              setPreview(false);
                            } else {
                              setFieldValue(
                                'videoLink',
                                collection?.url_video !== 'null'
                                  ? collection?.url_video
                                  : ''
                              );
                              if (
                                collection?.url_video &&
                                collection?.url_video !== 'null'
                              ) {
                                setPreview(true);
                              }
                            }
                          }}
                          id="video_a_utiliser"
                          className="peer sr-only"
                        />
                        <div className="peer h-6 w-11 rounded-full bg-gray-200 after:absolute after:left-[2px] after:top-[2px] after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-primary peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-primary" />
                      </label>
                    </div>
                  </div>
                  {addVideo && (
                    <label>
                      {(collection?.url_video === 'null' ||
                        collection?.url_video === null) && (
                        <span className="mr-2 block w-full grow">
                          Ajouter une vidéo
                        </span>
                      )}
                      {collection?.url_video !== 'null' &&
                        collection?.url_video !== null && (
                          <span className="mr-2 block w-full grow">
                            Modifier le lien de ma vidéo principale
                          </span>
                        )}

                      <div className="flex ">
                        <Field
                          name="videoLink"
                          type="text"
                          disabled={!addVideo}
                          className="block w-full grow rounded-md border border-gray-200 px-4 py-3 text-sm focus:border-primary focus:ring-primary"
                        />
                        {!preview && (
                          <button
                            type="button"
                            onClick={() => {
                              setPreview(true);
                              setValidVideo(true);
                            }}
                            className="ml-2 block w-1/3 rounded-md border border-gray-200 px-4 py-3 text-sm focus:border-primary focus:ring-primary"
                          >
                            Prévisualiser
                          </button>
                        )}
                      </div>
                    </label>
                  )}
                  {errors.videoLink && touched.videoLink ? (
                    <div className="text-red-400">{errors.videoLink}</div>
                  ) : null}

                  <label>
                    Modifier le titre de ma collecte
                    <Field
                      name="name"
                      className="block w-full rounded-md border border-gray-200 px-4 py-3 text-sm focus:border-primary focus:ring-primary"
                    />
                  </label>

                  <label htmlFor="description">
                    Description de ma collecte
                  </label>

                  <div className="relative rounded-md border border-gray-200">
                    <InternalEditor
                      content={content}
                      setContent={(ctn) => setFieldValue('description', ctn)}
                    />
                  </div>

                  <br />
                  <br />
                  <div className="flex w-full flex-col space-y-4 rounded-xl border p-4 shadow-sm md:p-5">
                    <h2 className="text-center text-lg text-black md:text-4xl">
                      Définissez la somme à collecter
                    </h2>
                    <div className="text-center text-2xl text-black">
                      <div className="mx-auto w-full max-w-lg">
                        <div className="my-5 flex items-center">
                          <span className="block w-full grow">
                            Montant libre
                          </span>
                          <label
                            htmlFor="fixer_le_montant"
                            className="relative inline-flex cursor-pointer items-center"
                          >
                            <input
                              type="checkbox"
                              // eslint-disable-next-line react/jsx-boolean-value
                              checked={fixedAmountToCollect}
                              onChange={(e) => {
                                setFieldValue(
                                  'hasAmountToCollect',
                                  e.target.checked
                                );
                                canDefine(e, 'amountToCollect');
                                if (e.target.checked) {
                                  setFieldValue('amountToCollect', '');
                                }
                              }}
                              id="fixer_le_montant"
                              className="peer sr-only"
                            />
                            <div className="peer h-6 w-11 rounded-full bg-gray-200 after:absolute after:left-[2px] after:top-[2px] after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-primary peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-primary" />
                          </label>
                        </div>
                      </div>
                      {!fixedAmountToCollect && (
                        <div className="mx-auto w-full max-w-lg">
                          <div className="my-5">
                            <span className="block w-full grow text-sm font-medium text-gray-900">
                              Fixer le montant
                            </span>
                          </div>
                          <div>
                            <div className="relative">
                              <Field
                                name="amountToCollect"
                                type="number"
                                min="0"
                                className="relative mx-auto w-full items-start justify-center rounded-full border-2 border-primary p-6 text-base leading-tight"
                              />
                              <span className="absolute right-5 top-5 text-gray-400/50">
                                €
                              </span>
                            </div>
                            {errors.amountToCollect &&
                            touched.amountToCollect ? (
                              <div className="text-sm text-red-400">
                                {errors.amountToCollect}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      )}
                      <h2 className="my-10 text-center text-lg text-black md:text-4xl">
                        Déterminez la visibilité
                      </h2>
                      <div className="flex flex-wrap items-center justify-center">
                        <div className="mr-5">
                          <span className="my-3 block">De votre collecte</span>
                          <label
                            htmlFor="de-votre-collecte"
                            className="relative inline-flex cursor-pointer items-center"
                          >
                            <input
                              type="checkbox"
                              checked={isPublic}
                              id="de-votre-collecte"
                              className="peer sr-only"
                              onChange={(e) => {
                                setFieldValue('isPublic', e.target.checked);
                                setIsPublic(e.target.checked);
                              }}
                            />
                            <div className="peer h-6 w-11 rounded-full bg-gray-200 after:absolute after:left-[2px] after:top-[2px] after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-primary peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-primary" />
                            <span className="ml-5 text-sm font-medium text-gray-900">
                              Publique
                            </span>
                          </label>
                        </div>
                        <div>
                          <span className="my-3 block">
                            Du montant collecté
                          </span>
                          <label
                            htmlFor="montant-collecte"
                            className="relative inline-flex cursor-pointer items-center"
                          >
                            <input
                              type="checkbox"
                              checked={isAmountPublic}
                              id="montant-collecte"
                              className="peer sr-only"
                              onChange={(e) => {
                                setFieldValue(
                                  'isAmountPublic',
                                  e.target.checked
                                );
                                setIsAmountPublic(e.target.checked);
                              }}
                            />
                            <div className="peer h-6 w-11 rounded-full bg-gray-200 after:absolute after:left-[2px] after:top-[2px] after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-primary peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300" />
                            <span className="ml-5 text-sm font-medium text-gray-900">
                              Public
                            </span>
                          </label>
                        </div>
                      </div>
                      <h2 className="my-10 text-center text-lg text-black md:text-4xl">
                        Indiquez la date de fin de votre collecte
                      </h2>
                      <div className="mx-auto w-full max-w-lg">
                        <div className="my-5 flex items-start">
                          <span className="block w-full grow">
                            Durée à déterminer
                          </span>
                          <div>
                            <label
                              htmlFor="duree_a_determiner"
                              className="relative inline-flex cursor-pointer items-center"
                            >
                              <input
                                type="checkbox"
                                checked={addEndingDate}
                                onChange={(e) => {
                                  setFieldValue(
                                    'hasEndingDate',
                                    e.target.checked
                                  );
                                  canDefine(e, 'endingDate');
                                }}
                                id="duree_a_determiner"
                                className="peer sr-only"
                              />
                              <div className="peer h-6 w-11 rounded-full bg-gray-200 after:absolute after:left-[2px] after:top-[2px] after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-primary peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-primary" />
                            </label>
                          </div>
                        </div>
                        <div>
                          <div
                            ref={calendarDateRef}
                            role="button"
                            onClick={openCalendar}
                            className="relative flex w-full flex-col items-start overflow-x-auto sm:overflow-x-visible"
                            aria-hidden="true"
                          >
                            <div className="w-full">
                              <Field
                                name="endingDate"
                                type="date"
                                disabled={!addEndingDate}
                                className="relative mx-auto w-full items-start justify-center rounded-full border-2 border-primary p-6 text-base leading-tight"
                              />
                              {showCalendar && addEndingDate && (
                                <Calendar
                                  className="rounded shadow-md shadow-slate-200 sm:left-0 sm:top-full sm:z-10 lg:absolute"
                                  date={endingDate}
                                  onShownDateChange={(date) => {
                                    const currentDate = new Date(date);
                                    const currentBirthDate = new Date(date);
                                    // set birthDate to current date
                                    currentDate.setDate(
                                      endingDate.getDate() + 1
                                    );
                                    currentBirthDate.setDate(
                                      endingDate.getDate()
                                    );
                                    setFieldValue(
                                      'endingDate',
                                      currentDate.toISOString().split('T')[0]
                                    );
                                    setEndingDate(currentBirthDate);
                                    setShowCalendar(false);
                                  }}
                                  onChange={(date) => {
                                    const currentDate = new Date(date);
                                    // add 1 day to current date
                                    currentDate.setDate(
                                      currentDate.getDate() + 1
                                    );
                                    setFieldValue(
                                      'endingDate',
                                      currentDate.toISOString().split('T')[0]
                                    );
                                    setEndingDate(date);
                                    setShowCalendar(false);
                                  }}
                                  locale={fr}
                                  color="#FD6E56B7"
                                />
                              )}
                            </div>
                          </div>

                          {errors.endingDate && touched.endingDate ? (
                            <div className="text-sm text-red-400">
                              {errors.endingDate}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  {message.length > 0 && (
                    <div
                      className={`mb-4 text-center text-sm ${
                        message[0] ? 'text-primary' : 'text-red-500'
                      }`}
                    >
                      {message[1]}
                    </div>
                  )}
                  <div className="flex">
                    <button
                      type="submit"
                      className={`button-glassmorphism relative mx-auto w-fit items-start justify-center rounded-full px-16 py-2 text-base font-bold leading-tight text-white
                      ${!isUpdating && 'duration-300 hover:scale-105'}`}
                    >
                      {isUpdating ? 'En cours' : 'Sauvegarder'}
                    </button>
                  </div>
                  <div className="flex justify-end">
                    <button
                      type="button"
                      onClick={handleDeleteCollect}
                      disabled={isDeleting}
                      className="flex items-center leading-none text-red-500 transition-colors hover:text-red-600"
                    >
                      <IconTrash color="currentColor" />
                      <span className="ml-3 mt-1">
                        {isDeleting
                          ? 'Suppression...'
                          : 'Supprimer ma collecte'}
                      </span>
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </section>
        )}
      </section>
    </div>
  );
};

export default Editcollection;
