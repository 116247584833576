import React from 'react';

const IconCheckCircle = ({ color, dimension = '24px' }) => {
  return (
    <svg
      width={dimension}
      height={dimension}
      strokeWidth="1.5"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="mb-[0.1rem]"
      color={color}
    >
      <path
        d="M7 12.5l3 3 7-7"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
IconCheckCircle.propTypes = {
  color: IconCheckCircle.string,
  dimension: IconCheckCircle.string,
};
export default IconCheckCircle;
