import React from 'react';
import { Helmet } from 'react-helmet';

import Banner from '../components/Banner';

const HowItWork = () => {
  return (
    <div className="container mx-auto max-w-7xl md:px-8 lg:px-24">
      <Helmet title="Bienvenue sur KOUDPOUSS" />
      <Banner
        image="piggy_banks.webp"
        currentPage={2}
        headerMessageContainer="items-end xl:ml-auto xl:mr-0 lg:text-left"
        bannerClass="h-banner-large"
      />
      <div className="bg-white">
        <section className="mx-auto max-w-5xl px-6 py-16 text-xl leading-8">
          <h1 className="text-center text-xl font-light text-black lg:text-2xl">
            La Tirelire Solidaire
          </h1>
          <p className="mt-1 text-center text-xl text-black  lg:text-2xl">
            Comment&nbsp;<span className="text-primary">ça marche </span>
          </p>
          <p className="my-10">
            Une plateforme qui permet de financer tous les projets , c’est bien
            ! Une plateforme qui récompense les porteurs de projet , c’est mieux
            . <br />
            Koud Pouss , c’est l’union des forces et le partage. <br /> Chaque
            mois , la Tirelire Solidaire récompense les utilisateurs ayant
            remporté le plus de votes dans leur catégorie . <br /> Alors , prêt
            à recueillir un max de votes ?
          </p>
          <h2 className="text-center text-xl font-light text-black lg:text-2xl">
            Comment <span className="text-primary">participer</span>
          </h2>
          <p className="my-10">
            Tout le monde peut participer . La Tirelire Solidaire peut être
            remportée deux fois consécutives par un même et unique porteur de
            projet sur un délais de 6 mois . <br /> En cas d’égalité, la
            récompense sera reversée au porteur de projet ayant remporté en
            premier le plus de votes .
          </p>
          <h2 className="text-center text-xl font-light text-black lg:text-2xl">
            Comment sont réparties{' '}
            <span className="text-primary">les catégories</span>
          </h2>
          <ul className="my-10 space-y-4">
            <li>Humanitaire</li>
            <li>Animalière</li>
            <li>
              Événements heureux :{' '}
              <span className="text-primary">
                Mariage, naissance, anniversaire, Événementiel .{' '}
              </span>
            </li>
            <li>
              Événements de la vie :{' '}
              <span className="text-primary">
                Solidarité, projets, pot de départ, non classée .
              </span>
            </li>
          </ul>
          <h2 className="text-center text-xl font-light text-black lg:text-2xl">
            Comment optimiser
            <span className="text-primary"> ses chances</span>
          </h2>
          <p className="my-10 leading-10">
            Un seul mot d’ordre : SMS ta team ! <br />
            Invitez vos amis , vos proches et pourquoi pas vos collègues !{' '}
            <br /> Mais pas seulement ! Diffusez votre initiative sur les
            réseaux sociaux , les webnautes seront ravis de vous donner un coup
            d’pouce ! Parce-que la toile c’est fait pour ça .
          </p>
          <h2 className="text-center text-xl font-light text-black lg:text-2xl">
            Conditions d’éligibilité{' '}
            <span className="text-primary">des collectes</span>
          </h2>
          <p className="my-10 leading-10">
            Sont éligibles à la Tirelire Solidaire les collectes ayant un
            montant minimum de 250 euros collectés et dont la création est
            inférieure à 61 jours inclus .
          </p>
          <h2 className="text-center text-xl font-light text-black lg:text-2xl">
            Ou et comment consulter{' '}
            <span className="text-primary">les résultats</span>
          </h2>
          <ul className="mt-10 space-y-4">
            <li>
              Les résultats seront consultables chaque 1er du mois dans la
              section Actualités de notre site.{' '}
            </li>
            <li>
              Pour suivre l’évolution des Tirelires et le classement des
              collectes , c’est par là .{' '}
            </li>
            <li>La Tirelire Solidaire, le p’tit coup d’pouce qui boost !</li>
          </ul>
        </section>
      </div>
    </div>
  );
};

export default HowItWork;
