/* eslint-disable no-unused-vars */
/* eslint-disable unused-imports/no-unused-vars */
import React from 'react';
import { Outlet } from 'react-router-dom';

import Footer from './components/Footer';

import('preline');

const Layout = () => {
  return (
    <>
      <main className="w-full text-gray-700 antialiased">
        <div className="max-w-screen mx-auto">
          <Outlet />
        </div>
      </main>
      <Footer />
    </>
  );
};

export default Layout;
