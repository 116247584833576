/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */
import '@splidejs/react-splide/css';
import '@splidejs/react-splide/css/core';
/* eslint-disable unused-imports/no-unused-vars */
import 'video-react/dist/video-react.css'; // import css

import { Splide, SplideSlide } from '@splidejs/react-splide';
import { getCategories } from 'api/category';
import {
  getActiveTirelire,
  getCollects,
  getTirelireAmount,
} from 'api/collection';
import Activity from 'components/Activity';
import ActivityWireFrame from 'components/ActivityWireframe';
import Banner from 'components/Banner';
import CountDownTimer from 'components/Countdown';
import Event from 'components/Event';
import Image from 'components/Image';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(advancedFormat);

const Index = () => {
  const BASE_PATH = `/assets/`;
  const [categories, setCategories] = useState([]);
  const slides = [
    {
      title:
        'Envie de faire une surprise à un pote ? <br/>C\'est easy, connecte ta <span class="text-hl">Team !</span>',
      src: 'slideshow/sld1.webp',
      position: 'bottom',
    },
    {
      title:
        'Des milliers de volontaires engagés dans une cause humanitaire oeuvrent chaque jour. <br/>Vous êtes leur <span class="text-hl">unique soutien</span>.',
      src: 'slideshow/sld2.webp',
      position: 'bottom',
    },
    {
      title:
        'Soutenez la cause animale. Nos amis les animaux ont besoin <span class="text-hl">de vous</span>.',
      src: 'slideshow/sld3.webp',
      position: 'bottom',
    },
    {
      title:
        'Koud Pouss, sponsor officiel des événements <span class="text-hl">heureux</span>.',
      src: 'slideshow/sld4.webp',
      position: 'bottom',
    },
    {
      title:
        'Etudier à l\'étranger ? Donner vie à <span class="text-hl">ses projets</span> ? C\'est maintenant !',
      src: 'slideshow/sld5.webp',
      position: 'center',
    },
    {
      title:
        'On a tous une bonne raison pour commencer à <span class="text-hl">collecter</span>. Foncez!',
      src: 'slideshow/sld6.webp',
      position: 'bottom',
    },
  ];

  const [activities, setActivities] = useState([]);
  const [loading, setLoading] = useState(true);
  const services = [
    {
      image: '/assets/happiness.webp',
      title:
        'Koud Pouss est une plateforme solidaire qui reverse une partie de ses contributions aux porteurs de projet !',
    },
    {
      image: '/assets/girl_left_parrot.webp',
      title:
        'Koud Pouss oeuvre à vos côtés parce-que chaque évènement de la vie mérite d’être célébré.',
    },
  ];
  const [slide, setSlide] = useState(services[0]);

  const collectionsStatic = [
    {
      mainImage: 'children_humanity.webp',
      centerImage: 'consignee.jpeg',
      cornerImage: 'rations.jpeg',
      asideImage: 'united.jpeg',
      color: 'text-emerald-800',
      name: 'Humanitaire',
      amount: '7000',
      fans: '200 Supporters',
      likes: {
        image: 'thumbs_up.png',
        number: '150 votes',
      },
    },
    {
      mainImage: 'white_dog_happy.png',
      centerImage: 'veterinarian.jpeg',
      cornerImage: 'animals.jpeg',
      asideImage: 'animals_friends.jpeg',
      color: 'text-blue-500',
      name: 'Animalière',
      amount: '10000',
      fans: '200 Supporters',
      likes: {
        image: 'thumbs_up.png',
        number: '200 votes',
      },
    },
    {
      mainImage: 'nature_wood.jpeg',
      centerImage: 'nature_wood.jpeg',
      cornerImage: 'mountains.jpeg',
      asideImage: 'breads.jpeg',
      color: 'text-blue-500',
      name: 'Vie',
      amount: '10000',
      fans: '200 Supporters',
      likes: {
        image: 'thumbs_up.png',
        number: '200 votes',
      },
    },
    {
      mainImage: 'happy_friends.webp',
      centerImage: 'solidarity_club.jpeg',
      cornerImage: 'with_a_smile.jpeg',
      asideImage: 'hand_in_hand.jpeg',
      color: 'text-blue-500',
      name: 'Solidarité',
      amount: '10000',
      fans: '200 Supporters',
      likes: {
        image: 'thumbs_up.png',
        number: '200 votes',
      },
    },
    {
      mainImage: 'wedding.jpeg',
      centerImage: 'cinderella_shoe.jpeg',
      cornerImage: 'weeding_love.jpeg',
      asideImage: 'bridal_dress.jpeg',
      color: 'text-blue-500',
      name: 'Mariage',
      amount: '10000',
      fans: '200 Supporters',
      likes: {
        image: 'thumbs_up.png',
        number: '200 votes',
      },
    },
  ];
  const [collections, setCollections] = useState([]);

  const [collection, setCollection] = useState(collectionsStatic[0]);
  const [collectionDynamic, setCollectionDynamic] = useState({
    mainImage: '',
    centerImage: 'consignee.jpeg',
    cornerImage: '',
    asideImage: 'united.jpeg',
    color: '',
    name: '',
    amount: '0',
    fans: '0 Supporters',
    likes: {
      image: 'thumbs_up.png',
      number: '0 votes',
    },
  });

  const [pot, setPot] = useState(0);
  const [name, setName] = useState('');
  const [tirelire, setTirelire] = useState(null);
  const [tirelireValue, setTirelireValue] = useState('');
  const [currentCollectIndex, setCurrentCollectIndex] = useState(0);

  const fetchCategories = () => {
    getCategories().then((res) => {
      setCategories(
        res?.data?.data?.sort((a, b) => a.created_at > b.created_at)
      );
    });
  };

  const fetchCollections = () => {
    getCollects()
      .then((res) => {
        setActivities(
          res.data.data.map((act) => {
            return {
              id: act.id,
              categoryId: act?.category_id,
              subTitle: act?.title,
              image: act?.image,
              slug: act?.slug,
              target: act?.montant_cagnotte,
              video:
                act?.url_video === 'null' || act?.url_video === null
                  ? ''
                  : act?.url_video,
              publicCollectedAmount: act?.is_public_collect_amount === 1,
              publicAmountGoal: act?.montant_is_libre === 1,
            };
          })
        );
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const fetchTirelireData = async () => {
    try {
      const response = await getActiveTirelire();
      const tirelireData = response.data.data;
      setTirelire(tirelireData);

      if (tirelireData?.collects?.length > 0) {
        const formattedCollections = tirelireData.collects.map((item) => ({
          id: item.collect.data.id,
          mainImage: item.collect.data.image,
          centerImage: item.collect.data.image,
          slug: item.collect.data.slug,
          cornerImage: item.collect.data.image,
          asideImage: item.collect.data.image,
          color: item.category.color,
          name: item.collect.data.title,
          amount: item.collect.data.collected_amount.toString(),
          fans: `${Math.floor(Math.random() * 500)} Supporters`,
          likes: {
            image: 'thumbs_up.png',
            number: `${Math.floor(Math.random() * 300)} votes`,
          },
        }));

        console.log('Collections formatées:', formattedCollections);
        setCollections(formattedCollections);

        if (formattedCollections.length > 0) {
          setCollectionDynamic(formattedCollections[0]);
        }
      }
    } catch (error) {
      console.error('Erreur lors du chargement de la tirelire:', error);
    }
  };

  const fetchTirelireTotal = () => {
    getTirelireAmount()
      .then((res) => {
        setTirelireValue(res.data.total_tirelire);
      })
      .catch((err) => {});
  };

  console.log(collections);
  useEffect(() => {
    fetchCategories();
    fetchCollections();
    fetchTirelireData();
    fetchTirelireTotal();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setSlide(services[Math.floor(Math.random() * services.length)]);
      setCollection(
        collectionsStatic[Math.floor(Math.random() * collectionsStatic.length)]
      );
      setPot(Math.floor(Math.random() * 2));
    }, 2000);

    return () => clearInterval(interval);
  }, [services, collectionsStatic]);

  useEffect(() => {
    // const interval = setInterval(() => {
    //   setSlide(services[Math.floor(Math.random() * services.length)]);
    //   setCollection(
    //     collectionsStatic[Math.floor(Math.random() * collectionsStatic.length)]
    //   );
    //   setPot(Math.floor(Math.random() * 2));
    // }, 2000);

    const otherInterval = setInterval(() => {
      if (collections.length > 0) {
        setCurrentCollectIndex((prevIndex) => {
          const newIndex =
            prevIndex === collections.length - 1 ? 0 : prevIndex + 1;
          // setCollectionDynamic(collections[newIndex]);
          console.log('interval');
          return newIndex;
        });
        // setPot(Math.floor(Math.random() * 2));
      }
    }, 5000);

    return () => {
      clearInterval(otherInterval);
    };
  }, [collections]);

  useEffect(() => {
    if (collections.length > 0 && currentCollectIndex < collections.length) {
      setCollectionDynamic(collections[currentCollectIndex]);
    }
  }, [currentCollectIndex, collections]);

  return (
    <div className="container mx-auto max-w-7xl md:px-8 lg:px-24">
      <Banner
        image={slides}
        currentPage={0}
        headerMessageContainer="items-end xl:ml-auto xl:mr-0 lg:text-left"
        bannerClass="h-banner-large"
        name={name}
        setName={setName}
      />
      <div className="bg-white text-center">
        <br />
        <br />
        <br className="block lg:hidden" />
        <br className="block lg:hidden" />
        <p className="mx-auto w-fit px-4 text-2xl font-bold text-white highlight highlight-primary highlight-variant-1 sm:text-4xl">
          0 Frais caché - 0 commission
        </p>
        <p className="mt-2 text-xs">
          Koud Pouss, sponsor officiel des causes qui vous sont chères
        </p>
        <h1
          id="categories"
          className="mx-auto px-3 py-7 text-center text-xl font-light text-black lg:text-2xl"
        >
          Une envie, une occasion, un événement à célébrer ? <br /> Koud Pouss
          est <span className="text-primary">La solution !</span>
        </h1>
        <ul className="container mx-auto hidden grid-cols-2 flex-wrap gap-2 md:grid md:px-8 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5">
          {categories?.map((event) => {
            return (
              <li key={`s_${event?.id}`} className="flex w-full justify-center">
                <Event data={event} name={name} />
              </li>
            );
          })}
        </ul>
        <Splide
          options={{
            perPage: 3,
            perMove: 3,
            arrows: false,
            pagination: false,
            autoplay: false,
            gap: 32,
            rewind: false,
            type: 'slide',
            breakpoints: {
              320: {
                perPage: 1,
                perMove: 1,
                gap: 10,
              },
              640: {
                perPage: 2,
                perMove: 2,
                gap: 64,
              },
            },
          }}
          className="md:hidden"
        >
          {categories?.map((event) => (
            <SplideSlide key={`spc_${event?.id}`}>
              <Event data={event} name={name} />
            </SplideSlide>
          ))}
        </Splide>
        <h2 className="mx-auto mb-16 mt-6 px-3 text-center text-2xl font-light text-black">
          Donnez vie à toutes vos envies et fédérez <br /> une communauté
          bienveillante autour de tous&nbsp;
          <span className="text-primary">vos projets !</span>
        </h2>
        <div
          className="bg-right relative flex items-end justify-center bg-no-repeat px-3 before:absolute before:h-full before:w-full before:bg-white lg:h-[19rem] lg:bg-cover lg:before:bg-transparent"
          style={{
            backgroundImage: `url(${BASE_PATH}montagne.7622a31d1e56afaab276.webp`,
          }}
        >
          <div className="relative z-10 mb-3 flex flex-col gap-8 lg:flex-row lg:gap-16">
            <figure className="h-full lg:w-1/2">
              <img
                src={slide.image}
                alt={slide.title}
                className="w-full -translate-y-0 scale-100 lg:-translate-y-[1.2rem] lg:scale-125"
              />
            </figure>
            <aside className="card-gradient relative mx-auto max-w-md self-start rounded-3xl px-10 py-8 text-center text-xl text-white before:absolute before:left-[-20px] before:border-y-[10px] before:border-y-transparent before:border-r-yellow-500 sm:px-24 md:bottom-8 lg:mx-0 lg:mt-10 before:lg:border-r-[30px]">
              <p>{slide.title}</p>
            </aside>
          </div>
        </div>
        <section className="container mx-auto my-16 items-center px-4 md:px-8 lg:flex lg:px-16">
          {/* <figure className="relative px-3 xl:w-1/2">
            <figcaption className="absolute left-[23%] top-6 w-full max-w-[40%] text-center font-bold text-black sm:left-32 sm:mt-2.5 xl:left-24">
              <h3 className="text-[8px] leading-7 sm:text-xl">
                Ta collecte est populaire <br /> Remporte la{' '}
                <span>Tirelire</span>
              </h3>
              <p className="text-sm sm:text-3xl">
                <span className="text-pink-400">10000 </span>€
              </p>
            </figcaption>
            <img
              src="/assets/piggy_bank.png"
              alt="La Tirelire Solidaire"
              title="La Tirelire Solidaire"
              className="w-full"
            />
          </figure> */}
          <figure className="pet max-w-full safari before:content[''] before:bg-bottom relative z-[1] h-full min-h-full w-full rounded-b-lg before:absolute before:-bottom-48 before:-left-16 before:z-50 before:h-full before:w-72 before:bg-contain before:bg-no-repeat md:mx-auto">
            <div className="relative z-10 flex w-full items-center rounded-t-lg bg-gray-300 px-16 py-2">
              <div className="absolute left-4 top-2/4 flex -translate-y-1 space-x-1">
                <span className="h-2 w-2 rounded-full bg-red-600" />
                <span className="h-2 w-2 rounded-full bg-yellow-600" />
                <span className="h-2 w-2 rounded-full bg-green-600" />
              </div>
              <div className="flex h-full w-full items-center justify-center rounded-sm bg-gray-400 py-0.5 text-[.5rem] text-gray-800">
                www.koud-pouss.com
              </div>
            </div>

            <div className="flex min-h-[15rem] w-full rounded-b-lg bg-white">
              <div className="relative z-20 mr-auto w-1/2 py-5">
                {pot === 0 && (
                  <div className="font-bold">
                    Ta collecte est <br />
                    <span className="text-primary">populaire ?</span>
                    <br />
                    <br />
                    Remporte la <br />
                    <span className="text-primary">Tirelire !</span>
                  </div>
                )}
                {pot === 1 && (
                  <div className="font-normal">
                    Ta collecte est
                    <span className="text-primary"> populaire ?</span>
                    <br />
                    <br />
                    Remporte la
                    <span className=" tex-primary"> Tirelire !</span>
                    <ul className="mt-4 inline-flex text-4xl font-extrabold">
                      {Array.from(tirelireValue?.split(''), (v, i) => (
                        <li
                          key={i}
                          className="count-up relative mr-1 flex items-center px-1"
                        >
                          <div className="flex items-center bg-gradient-to-b from-[#FFEAFF] to-[#C55F80] bg-clip-text text-transparent">
                            {v}
                          </div>
                        </li>
                      ))}
                      <li className="count-up relative mr-1 flex items-center px-1">
                        <div className="flex items-center bg-gradient-to-b from-[#FFEAFF] to-[#C55F80] bg-clip-text text-transparent">
                          €
                        </div>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
              <div className="relative w-1/2 overflow-hidden rounded-br-lg">
                <div className="flex items-center justify-center">
                  <img
                    className="scale-150"
                    src="/assets/ta-collecte-est-populaire.jpeg"
                    alt="La Tirelire"
                  />
                </div>
              </div>
            </div>
          </figure>
          <article className="mt-24 lg:mt-0 xl:ml-6 xl:max-w-md">
            <h2 className="text-2xl text-primary">
              La Tirelire Solidaire <br /> Le koud&apos;pouss qui boost !
            </h2>
            <p className="my-5 text-lg">
              Invitez vos amis à voter pour votre collecte afin de remporter la
              Tirelire Solidaire. Koud Pouss est Solidaire des causes qui vous
              tiennent à cœur. <br />
              <a href="/comment-ca-marche" className="text-yellow-500">
                En savoir plus
              </a>
            </p>

            <CountDownTimer
              date={
                tirelire
                  ? dayjs(tirelire.last_date).format()
                  : dayjs().endOf('month').add(1, 'second').format()
              }
            />
          </article>
        </section>
        <h2 className="mx-auto px-3 pb-16 pt-8 text-center text-2xl font-light text-black">
          Découvre les collectes les plus &nbsp;
          <span className="text-primary">populaires</span> et tente de remporter
          la tirelire solidaire !
        </h2>
        <section className="relative mx-auto mb-16 flex justify-center px-3 lg:container sm:px-8">
          <figure className="relative hidden sm:block sm:w-full">
            <div
              className="aspect-square bg-center ml-24 mr-10 flex h-full scale-100 items-end justify-end rounded-xl bg-cover bg-no-repeat"
              style={{
                backgroundImage: `url(${BASE_PATH}${collection.centerImage}`,
              }}
            >
              <figure className="-mb-4 rounded-l-full bg-white py-5 pl-5 text-right">
                <img
                  src="/assets/thumbs_up.png"
                  alt="Aime"
                  title="Aime"
                  className="mx-auto"
                />
                <span className="font-bold">{collection.likes.number}</span>
                {/* <img
                  src={`/assets/${collection.likes.image}`}
                  alt="Aime"
                  title="Aime"
                  className="mx-auto"
                />
                <span className="font-bold">{collection.likes.number}</span> */}
              </figure>
            </div>
            <div
              className="absolute top-0 h-20 w-32 scale-110 rounded-lg bg-cover bg-no-repeat"
              style={{
                backgroundImage: `url(${BASE_PATH}${collection.cornerImage}`,
              }}
            />
            <div
              className="absolute -bottom-4 left-0 h-20 w-20 scale-110 rounded-lg bg-cover bg-no-repeat"
              style={{
                backgroundImage: `url(/assets/ta-collecte-est-populaire.jpeg)`,
              }}
            />
          </figure>
          <article
            className={`inline-block text-sm ${collectionDynamic.color} max-w-xs sm:block`}
          >
            <h2 className="mb-7 text-xl font-bold">
              {/* <span className="text-lime-900">Collecte </span> */}
              {collectionDynamic.name}
            </h2>
            {/* <p className="mb-3 text-center font-bold text-emerald-800">
              Soutenons l’association <br /> Maraca
            </p> */}

            <figure>
              <img
                src={`${collectionDynamic.mainImage}`}
                alt=""
                title={collectionDynamic.name}
                className="h-[250px] w-[300px] object-cover"
              />
            </figure>

            <div className="mt-1 flex items-center rounded-sm border border-gray-200">
              {/* <img
                src="/assets/gain.png"
                alt="Collecte"
                title="Montant collecté"
                className="mr-3 w-12"
              /> */}
              <div className="grow text-center">
                <strong className="text-2xl">
                  {collectionDynamic.amount} €
                </strong>{' '}
                <br />
                <span className="">collectés</span>
              </div>
            </div>
            <aside className="mt-3 flex items-center">
              <Link
                to={`/collecte/${collectionDynamic.slug}`}
                className="mr-4 w-24 rounded-full bg-yellow-500 py-2 text-center text-xs text-white"
              >
                Voir plus
              </Link>
              {/* <figure className="flex items-center">
                <img
                  src="/assets/team.png"
                  alt="Collecte"
                  title="Supporters"
                  className="mr-3 w-20"
                />
                <figcaption className="grow text-center text-xs font-bold">
                  {collection.fans}
                </figcaption>
              </figure> */}
            </aside>
          </article>
        </section>
        <h2 className="mx-auto px-3 pb-10 text-center text-2xl font-normal text-black">
          Une <span className="text-primary">collecte</span> pour toutes les
          causes et occasions qui vous <br />
          <span className="text-blue-600">ressemble</span> et{' '}
          <span className="text-green-600">nous rassemble !</span>
        </h2>
        {/* <div className="koudpouss-player mx-auto mb-16 flex max-w-4xl justify-center px-6 md:px-8 lg:px-24">
          <figure className="max-w-full mx-auto h-full w-full">
            <div className="rounded-3xl bg-gray-800 p-1.5 shadow-[0_2.75rem_5.5rem_-3.5rem_rgb(45_55_75_/_20%),_0_2rem_4rem_-2rem_rgb(45_55_75_/_30%),_inset_0_-0.1875rem_0.3125rem_0_rgb(45_55_75_/_20%)]">
              <Player
                playsInline
                fluid={false}
                height="100%"
                width="auto"
                // poster="/assets/events.jpeg"
                src="/assets/koudpouss.mp4"
              />
            </div>
          </figure>
        </div> */}

        <div className="overflow-hidden">
          <figure className="relative mb-14 bg-primary pl-5 after:absolute after:-bottom-5 after:left-0 after:border-r-[70vw] after:border-t-[20px] after:border-r-transparent after:border-t-orange-400">
            <section
              className="bg-center h-96 bg-cover bg-no-repeat sm:py-72"
              style={{
                backgroundImage: `url(${BASE_PATH}children-1822704_1920.jpg`,
              }}
            />
            <figcaption className="absolute left-0 top-24 w-full">
              <h2 className="mx-auto max-w-7xl px-6 text-center text-lg font-bold text-white lg:text-3xl">
                Faire appel à la solidarité et fédérer une team motivée <br />
                autour de votre projet n’aura jamais été aussi <br />
                <span className="text-primary">facile.</span>
              </h2>
            </figcaption>
          </figure>
        </div>
        <h2 className="my-10 px-3 text-center text-2xl">
          Ils ont rejoint <span className="text-primary">Koud Pouss</span>
          <br />
          Et ils ont eu raison
        </h2>
        <Splide
          options={{
            perPage: 1,
            perMove: 1,
            arrows: true,
            pagination: false,
            autoplay: false,
            gap: 34,
            rewind: false,
            type: 'slide',
            breakpoints: {
              640: {
                perPage: 1,
                perMove: 1,
                gap: 10,
              },
              980: {
                perPage: 2,
                perMove: 2,
                gap: 10,
              },
              1024: {
                perPage: 3,
                perMove: 3,
                gap: 10,
              },
              1280: {
                perPage: 4,
                perMove: 4,
                gap: 10,
              },
            },
          }}
        >
          {activities.map((activity) => (
            <SplideSlide
              key={`spc_${activity?.id}`}
              className="w-full max-w-xs"
            >
              <Activity data={activity} />
            </SplideSlide>
          ))}

          {activities?.length === 0 &&
            loading &&
            [0, 1, 2, 3].map((i) => (
              <SplideSlide key={`spc_${i}`} className="w-full max-w-xs">
                <ActivityWireFrame />
              </SplideSlide>
            ))}
        </Splide>
        <div className="my-8 flex justify-center">
          <a
            className="rounded-full bg-primary px-4 py-2 text-sm font-semibold text-white"
            href="/collectes"
          >
            Voir toutes les collectes
          </a>
        </div>
        <ul className="container mx-auto grid grid-cols-1 gap-8 px-6 md:px-8 lg:grid-cols-2 lg:px-24">
          <li>
            <article className="flex h-full flex-col rounded-3xl bg-yellow-500/90 px-10 py-8 text-center text-xl text-white">
              <p className="flex-1">
                Koud Pouss, la plateforme qui encourage les initiatives
                solidaires et innove la collecte en ligne. Collecter de chez soi
                devient plus simple et plus sympa avec Koud Pouss.
              </p>

              <div className="mt-auto flex flex-col items-center space-y-4">
                <div className="inline-flex rounded-2xl border-b-4 border-black bg-yellow-500/90 px-8 py-5 text-white">
                  {[1, 2, 3, 4, 5].map((index) => (
                    <div
                      key={index}
                      className="relative mx-1 flex h-10 w-8 items-center justify-center bg-black"
                    >
                      {/* <span className="absolute -top-1 left-0">&bull;</span> */}
                      {index}
                      {/* <span className="absolute -top-1 right-0">&bull;</span> */}
                    </div>
                  ))}
                </div>
                <p className="font-bold">€ cagnottes</p>
              </div>
            </article>
          </li>

          <li>
            <article className="flex h-full flex-col rounded-3xl bg-yellow-500/90 px-10 py-8 text-center text-xl text-white">
              <p className="flex-1">
                L&apos;union des forces dans un but commun. Koud Pouss vous
                accompagne afin de vous aider à réaliser vos objectifs.
              </p>

              <div className="mt-auto flex flex-col items-center space-y-4">
                <div className="inline-flex rounded-2xl border-b-4 border-black bg-yellow-500/90 px-8 py-5 text-white">
                  {[1, 2, 3, 4, 5].map((index) => (
                    <div
                      key={index}
                      className="relative mx-1 flex h-10 w-8 items-center justify-center bg-black"
                    >
                      {/* <span className="absolute -top-1 left-0">&bull;</span> */}
                      {index}
                      {/* <span className="absolute -top-1 right-0">&bull;</span> */}
                    </div>
                  ))}
                </div>
                <p className="font-bold">Causes soutenues</p>
              </div>
            </article>
          </li>
        </ul>
        <h2 className="mb-6 px-3 text-center text-2xl font-light sm:mb-16">
          Notre priorité :
          <span className="mb-2 mt-4 block">
            La solidarité pour des porteurs de projets satisfaits.
          </span>
          Connectez votre team et donnez vie à vos{' '}
          <span className="mt-2 text-primary">projets !</span>
        </h2>
        <ul className="container mx-auto text-center text-xl font-bold sm:flex md:px-8 lg:px-24">
          <li className="mb-6 sm:w-1/3">
            <figure>
              <Image
                src="/assets/secure.png"
                loader="/assets/secure-tiny.png"
                alt="SSL"
                title="Plateforme de Dons sécurisés"
                className="mx-auto h-40"
              />
              <figcaption className="mt-5">
                Plateforme de <br /> Dons sécurisés
              </figcaption>
            </figure>
          </li>
          <li className="mb-6 sm:w-1/3">
            <figure>
              <Image
                src="/assets/account.png"
                loader="/assets/account-tiny.png"
                alt="Smartphones - tablettes"
                title="Comptabilité Smartphones - tablettes"
                className="mx-auto h-40"
              />
              <figcaption className="mt-5">
                Comptabilité <br /> Smartphones & tablettes
              </figcaption>
            </figure>
          </li>
          <li className="mb-6 sm:w-1/3">
            <figure>
              <Image
                src="/assets/fly.png"
                loader="/assets/fly-tiny.png"
                alt="Fusée"
                title="Booster votre collecte Grace à nos services"
                className="mx-auto h-40"
              />
              <figcaption className="mt-5">
                Booster votre collecte <br /> Grace à nos services
              </figcaption>
            </figure>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Index;
