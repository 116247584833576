import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Button from './Button';

const Footer = () => {
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [message, setMessage] = useState({});

  const listMenu = [
    {
      class: 'md:w-4/12',
      name: 'Menu',
      column: [
        {
          name: '',
          link: '',
          items: [
            {
              name: 'Contact',
              link: '/contactez-nous',
            },
            {
              name: 'A propos de nous',
              link: '/a-propos-de-nous',
            },
            {
              name: 'Questions fréquentes',
              link: '/faq',
            },
            {
              name: 'Toutes les collectes',
              link: '',
            },
          ],
        },
        {
          name: '',
          link: '',
          items: [
            {
              name: 'Actualité Koud Pouss',
              link: '',
            },
            {
              name: 'Tarifs',
              link: '/tarifs',
            },
            {
              name: 'CGU',
              link: '',
            },
            {
              name: 'Politique de confidentialité',
              link: '/politique-de-confidentialite',
            },
          ],
        },
      ],
    },
    {
      class: 'md:w-4/12',
      name: 'Nous contacter',
      column: [
        {
          name: '',
          link: '',
          items: [
            // {
            //   name: '101 avenue du Général Leclerc 75685 PARIS CEDEX 14',
            //   icon: 'fas fa-map-marker-alt',
            //   link: '',
            //   class: 'md:!w-full',
            // },
            {
              name: '0663151989',
              icon: 'fas fa-phone',
              link: 'tel:0663151989',
              class: 'md:!w-full',
            },
            {
              name: 'contact@koud-pouss.com',
              icon: 'fas fa-envelope',
              link: 'mailto:contact@koud-pouss.com',
              class: 'md:!w-full',
            },
          ],
        },
      ],
    },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();

    if (name) {
      navigate(`/lancer-une-collecte/nom/${name}`);
    } else {
      setMessage({
        type: 'error',
        message: 'Veuillez saisir un titre pour votre collecte',
      });
    }
  };

  return (
    <footer className="container mx-auto max-w-7xl text-black md:px-8 lg:px-24">
      <nav
        className="footer px-8 py-20 text-white md:flex"
        aria-label="Footer navigation"
      >
        <figure className="mt-5 h-full w-1/2 self-end md:w-1/12 md:pb-4">
          <img src="/logo-black.png" alt="Koudpouss" />
        </figure>
        {listMenu.map((item) => (
          <ul
            key={`l_${item.name}`}
            className={`flex-col text-sm md:ml-10 md:flex ${item.class} border-gray-white pr-10 lg:border-r`}
          >
            {item.link &&
              item.column.map((col) => (
                <li key={`c_${col.name}_${col.link}`} className="mt-5">
                  {col.link ? (
                    <a href={col.link}>
                      {!col.name ? (
                        <span aria-hidden="true">&nbsp;</span>
                      ) : (
                        col.name
                      )}
                    </a>
                  ) : (
                    <span>{col.name}</span>
                  )}
                </li>
              ))}
            {!item.link && (
              <li
                key={`i_${item.name}`}
                className="mt-5 text-2xl font-extrabold"
              >
                {item.name}
              </li>
            )}
            {item.name &&
              item.column.map((col) => (
                <ul key={`c_${col.name}_m${col.link}`}>
                  <li className="mt-5 font-semibold">{col.name}</li>
                  <ul className="ml-4 flex flex-wrap">
                    {col.items.map((it) => (
                      <li
                        key={`it_${it.name}${it.link}`}
                        className={`mt-5 w-full md:w-1/2 ${it.class}`}
                      >
                        {!it.link ? (
                          <span>
                            {it.icon && (
                              <i
                                className={`${it.icon} mr-2`}
                                aria-hidden="true"
                              />
                            )}
                            {it.name}
                          </span>
                        ) : (
                          <a href={it.link}>
                            {it.icon && (
                              <i
                                className={`${it.icon} mr-2`}
                                aria-hidden="true"
                              />
                            )}
                            {it.name}
                          </a>
                        )}
                      </li>
                    ))}
                  </ul>
                </ul>
              ))}
          </ul>
        ))}
        <div className="mt-4 flex w-full flex-row md:ml-10 md:mt-0 md:w-3/12 md:justify-end md:pr-4">
          <div className="flex w-auto max-w-lg flex-col justify-between space-y-6 md:space-y-0 md:pr-4">
            <form
              className="flex flex-col justify-between"
              onSubmit={handleSubmit}
            >
              <div className="flex space-x-2 md:hidden">
                <input
                  name="name"
                  type="text"
                  placeholder="Donne un titre à ta collecte"
                  className="mx-auto w-[180%] rounded-full border border-white bg-transparent p-2 text-sm text-white placeholder:text-white"
                  onChange={(e) => setName(e.target.value)}
                  aria-label="Titre de la collecte (version mobile)"
                />

                <Button
                  type="submit"
                  text="C'est parti !"
                  className="bg-glassmorphism mx-auto w-full whitespace-nowrap bg-white !px-2 text-sm text-white"
                />
              </div>

              <input
                name="name"
                placeholder="Donne un titre à ta collecte"
                className="mx-auto hidden w-full rounded-full border border-white bg-transparent p-4 text-xs text-white placeholder:text-white md:block"
                onChange={(e) => setName(e.target.value)}
                aria-label="Titre de la collecte (version desktop)"
              />

              <p
                className={`mt-1 text-center text-xs
              ${message.type === 'error' ? 'block' : 'hidden'}
              `}
                role="alert"
              >
                {message.message}
              </p>
              <span aria-hidden="true" className="md:hidden">
                <br />
              </span>

              <Button
                type="submit"
                text="C'est parti !"
                className="bg-glassmorphism mx-auto hidden w-fit bg-white text-xs  text-white md:block"
              />
            </form>

            <div className="flex w-full max-w-lg flex-row justify-between pr-4">
              <a
                href="https://twitter.com/koudpouss"
                target="_blank"
                rel="noopener noreferrer"
                className="flex h-12 w-12 items-center justify-center rounded-full bg-light"
                aria-label="Twitter"
              >
                <i
                  className="fab fa-twitter text-xl text-primary"
                  aria-hidden="true"
                />
              </a>
              <a
                href="https://www.instagram.com/koudpouss/"
                target="_blank"
                rel="noopener noreferrer"
                className="flex h-12 w-12 items-center justify-center rounded-full bg-light"
                aria-label="Instagram"
              >
                <i
                  className="fab fa-instagram text-xl text-primary"
                  aria-hidden="true"
                />
              </a>
              <a
                href="https://www.facebook.com/koudpouss"
                target="_blank"
                rel="noopener noreferrer"
                className="flex h-12 w-12 items-center justify-center rounded-full bg-light"
                aria-label="Facebook"
              >
                <i
                  className="fab fa-facebook-f text-xl text-primary"
                  aria-hidden="true"
                />
              </a>
            </div>
          </div>
        </div>
        <span aria-hidden="true" className="md:hidden">
          <br />
        </span>
      </nav>
    </footer>
  );
};

export default Footer;
