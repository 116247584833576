/* eslint-disable import/no-unresolved */
/* eslint-disable */
/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
import { Splide, SplideSlide } from '@splidejs/react-splide';
import Event from 'components/Event';
import React from 'react';
import { Helmet } from 'react-helmet';

import Banner from '../components/Banner';
import { useState } from 'react';
import { getTirelirePerEvent } from 'api/collection';
import { useEffect } from 'react';

const PopularCollection = () => {
  const Collections = [
    {
      image: 'solidarity.jpeg',
      name: 'Évènements de la vie',
      amount: '3750 €',
      categories: [
        {
          title: 'Solidarité',
          image: 'assets/solidarity.jpeg',
          imageDim: 'w-28 h-28',
          cardBackground: 'bg-slate-400/20 lg:bg-white',
          color: 'rgb(248, 176, 58)',
        },
        {
          title: 'Projet',
          image: 'assets/project.jpeg',
          imageDim: 'w-28 h-28',
          cardBackground: 'bg-slate-400/20 lg:bg-white/80',
          color: 'rgb(209, 192, 176)',
        },
        {
          title: 'Pot de départ',
          image: 'assets/farewell_party.jpg',
          imageDim: 'w-28 h-28',
          color: 'rgb(100, 71, 53)',
        },
        {
          title: 'Non classés',
          image: 'assets/unclassified.jpeg',
          imageDim: 'w-28 h-28',
          color: 'rgb(216, 230, 55)',
        },
      ],
    },
    {
      image: 'events.jpeg',
      name: 'Événements heureux',
      amount: '4000 €',
      categories: [
        {
          title: 'Mariage',
          image: 'assets/wedding.jpeg',
          imageDim: 'w-28 h-28',
          cardBackground: 'bg-slate-400/20 lg:bg-white',
          color: 'rgb(237, 109, 217)',
        },
        {
          title: 'Naissance',
          image: 'assets/birth.jpg',
          imageDim: 'w-28 h-28',
          cardBackground: 'bg-slate-400/20 lg:bg-white/80',
          color: 'rgb(154, 104, 175)',
        },
        {
          title: 'Anniversaire',
          image: 'assets/birthday.jpg',
          imageDim: 'w-28 h-28',
          color: 'rgb(216, 230, 55)',
        },
        {
          title: 'Évenementiel',
          image: 'assets/events.jpeg',
          imageDim: 'w-28 h-28',
          color: 'rgb(243, 70, 55)',
        },
      ],
    },
    {
      image: 'humanitaire.jpg',
      name: 'Humanitaire',
      amount: '5000 €',
      categories: [
        {
          title: 'Humanitaire',
          image: 'assets/humanitaire.jpg',
          imageDim: 'w-28 h-28',
          cardBackground: 'bg-slate-400/20 lg:bg-white/80',
          color: 'rgb(46, 110, 72)',
        },
      ],
    },
    {
      image: 'animal.jpg',
      name: 'Animalière',
      amount: '5000 €',
      categories: [
        {
          title: 'Animalière',
          image: 'assets/animal.jpg',
          imageDim: 'w-28 h-28',
          color: 'rgb(120, 180, 251)',
        },
      ],
    },
  ];

  const [eventsTirelire, setEventsTirelire] = useState({
    happyEvent: "0",
    lifeEvent: "0",
    humanityEvent: "0",
    animalyEvent: "0"
  })
  const [loading, setLoading] = useState(false)

  const fetchEventsData = async () => {
    setLoading(true)
    try {
      const [data1, data2, data3, data4] = await Promise.allSettled([getTirelirePerEvent("happy_event"), getTirelirePerEvent("life_event"), getTirelirePerEvent("humanity_event"), getTirelirePerEvent("animaly_event")])
      if (data1.value) {
        setEventsTirelire((prevValue) => ({...prevValue, happyEvent: data1.value.data?.total_tirelire}))
      }
      if (data2.value) {
        setEventsTirelire((prevValue) => ({...prevValue, lifeEvent: data2.value.data?.total_tirelire}))
      }
      if (data3.value) {
        setEventsTirelire((prevValue) => ({...prevValue, humanityEvent: data3.value.data?.total_tirelire}))
      }
      if (data4.value) {
        setEventsTirelire((prevValue) => ({...prevValue, animalyEvent: data4.value.data?.total_tirelire}))
      }
      setLoading(false)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchEventsData()
  }, [])
  return (
    <div className="container mx-auto max-w-7xl md:px-8 lg:px-24">
      <Helmet title="Ma collecte populaire - KOUDPOUSS" />
      <Banner
        image="collete-populaire.webp"
        currentPage={3}
        headerMessageContainer="items-end xl:ml-auto xl:mr-0 lg:text-left"
        bannerClass="h-banner-large"
      />
      <div className="mx-auto bg-white px-6 py-16 text-xl leading-8">
        <section className="mx-auto max-w-7xl">
          <h1 className="mb-16 text-center text-xl font-light text-black lg:text-2xl">
            Ta collecte est&nbsp;<span className="text-primary">populaire</span>
          </h1>
          <p className="mx-auto max-w-6xl text-xl lg:text-2xl">
            <span className="text-primary">La Tirelire Solidaire</span>
            &nbsp;récompense les porteurs de projet et c’est uniquement sur{' '}
            <span className="text-primary">Koud Pouss !</span> <br />
            C’est le coup d’pouce sympa, l’union des forces et la solidarité
            parce-qu’une plateforme qui permet de financer tous les projets ,
            c’est bien, mais une plateforme qui récompense les porteurs de
            projet , c’est mieux ! voir conditions
          </p>
          <h2 className="my-32 text-center text-xl font-light text-black lg:text-2xl">
            Découvrez le classement des{' '}
            <span className="text-primary">collectes. </span>
          </h2>
          <ul>
            {Collections.map((Collection) => {
              return (
                <li className="relative items-center 2xl:mb-16 2xl:flex">
                  <figure
                    className="h-80 bg-cover bg-no-repeat 2xl:h-[32rem] 2xl:w-1/2"
                    style={{
                      backgroundImage: `url(/assets/${Collection.image})`,
                    }}
                  />
                  <article className="w-full bg-white/20 py-5 backdrop-blur-xl xl:left-1/3 2xl:absolute 2xl:max-w-3xl 2xl:px-5">
                    <h3 className="text-3xl font-bold underline">
                      {Collection.name}
                    </h3>
                    <figure className="flex flex-wrap items-center">
                      <img
                        src="/assets/piggy_icon.png"
                        title="Collecte"
                        alt="Collecte"
                        className="mr-5 mt-5"
                      />
                      <figcaption className="mt-5 flex grow flex-wrap justify-between">
                        <span className="text-2xl font-bold">
                          {Collection.name === 'Évènements de la vie' ? `${eventsTirelire.lifeEvent} €` : Collection.name === 'Événements heureux' ? `${eventsTirelire.happyEvent} €` : Collection.name === 'Humanitaire' ? `${eventsTirelire.humanityEvent} €` : `${eventsTirelire.animalyEvent} €`}
                        </span>
                        <a href={`/classement/${Collection.name === 'Évènements de la vie' ? 'life_event' : Collection.name === 'Événements heureux' ? 'happy_event' : Collection.name === 'Humanitaire' ? 'humanity_event' : 'animaly_event'}`}>
                          <span className="relative w-fit items-start justify-center rounded-full bg-gradient-to-b from-orange-100 to-primary px-9 py-4 text-base font-bold leading-tight text-white">
                            Voir le classement
                          </span>
                        </a>
                      </figcaption>
                    </figure>
                    <ul className="hidden min-w-[32rem] flex-wrap justify-between py-5 md:inline-flex">
                      {Collection.categories.map((event, eventIndex) => {
                        return (
                          <li
                            key={event?.title}
                            className={`flex w-48 justify-center ${
                              eventIndex !== Collection.categories.length - 1 &&
                              'mr-2'
                            }`}
                          >
                            <Event data={event} />
                          </li>
                        );
                      })}
                    </ul>
                    <Splide
                      options={{
                        perPage: 1,
                        perMove: 1,
                        arrows: true,
                        pagination: false,
                        autoplay: false,
                        gap: 34,
                        rewind: false,
                        type: 'slide',
                      }}
                      className="md:hidden"
                    >
                      {Collection.categories.map((event) => (
                        <SplideSlide
                          key={event?.title}
                          className="flex justify-center"
                        >
                          <Event data={event} />
                        </SplideSlide>
                      ))}
                    </Splide>
                  </article>
                </li>
              );
            })}
          </ul>
        </section>
      </div>
    </div>
  );
};

export default PopularCollection;
